import { useCallback } from 'react';
import { collection, doc, setDoc, getDocs, query, orderBy, deleteDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';

export interface Treatment {
  id: string;
  title: string;
  pdfUrl: string;
  pptxUrl: string;
  createdAt: Date;
  updatedAt: Date;
}

export function useTreatmentStorage() {
  const { user } = useAuth();

  const saveTreatment = useCallback(async (data: Omit<Treatment, 'id'>) => {
    if (!user) throw new Error('User not authenticated');

    const treatmentsRef = collection(db, 'users', user.uid, 'treatments');
    const treatmentDoc = doc(treatmentsRef);
    
    try {
      const treatmentData = {
        ...data,
        updatedAt: new Date(),
        createdAt: data.createdAt || new Date()
      };

      await setDoc(treatmentDoc, treatmentData);
      return treatmentDoc.id;
    } catch (error) {
      console.error('Error saving treatment:', error);
      throw new Error('Failed to save treatment');
    }
  }, [user]);

  const getTreatments = useCallback(async (): Promise<Treatment[]> => {
    if (!user) return [];

    try {
      const treatmentsRef = collection(db, 'users', user.uid, 'treatments');
      const q = query(treatmentsRef, orderBy('createdAt', 'desc'));
      const querySnapshot = await getDocs(q);
      
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        updatedAt: doc.data().updatedAt.toDate()
      })) as Treatment[];
    } catch (error) {
      console.error('Error fetching treatments:', error);
      return [];
    }
  }, [user]);

  const deleteTreatment = useCallback(async (treatmentId: string, pdfUrl: string, pptxUrl: string) => {
    if (!user) throw new Error('User not authenticated');

    try {
      // Delete from Firestore
      const treatmentRef = doc(db, 'users', user.uid, 'treatments', treatmentId);
      await deleteDoc(treatmentRef);

      // Delete files from Uploadcare
      const fileIds = [
        pdfUrl.split('/').pop(),
        pptxUrl.split('/').pop()
      ].filter(Boolean);

      for (const fileId of fileIds) {
        if (!fileId) continue;
        
        try {
          await fetch(`https://api.uploadcare.com/files/${fileId}/`, {
            method: 'DELETE',
            headers: {
              'Authorization': `Uploadcare.Simple 6ed4dd3601e7b4d0d8d6:`,
              'Accept': 'application/vnd.uploadcare-v0.7+json'
            }
          });
        } catch (error) {
          console.error('Error deleting file from Uploadcare:', error);
          // Continue with other deletions even if one fails
        }
      }
    } catch (error) {
      console.error('Error deleting treatment:', error);
      throw new Error('Failed to delete treatment');
    }
  }, [user]);

  return { saveTreatment, getTreatments, deleteTreatment };
}