import React, { createContext, useContext, useState } from 'react';

interface ImageUrls {
  [key: string]: string; // Format: "slide-{slideNumber}-image-{imageIndex}"
}

interface ImageUrlContextType {
  imageUrls: ImageUrls;
  updateImageUrl: (slideNumber: number, imageIndex: number, url: string) => void;
  getAllImageUrls: () => ImageUrls;
}

const ImageUrlContext = createContext<ImageUrlContextType | null>(null);

export const useImageUrls = () => {
  const context = useContext(ImageUrlContext);
  if (!context) {
    throw new Error('useImageUrls must be used within an ImageUrlProvider');
  }
  return context;
};

export const ImageUrlProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [imageUrls, setImageUrls] = useState<ImageUrls>({});

  const updateImageUrl = (slideNumber: number, imageIndex: number, url: string) => {
    setImageUrls(prev => ({
      ...prev,
      [`slide-${slideNumber}-image-${imageIndex}`]: url
    }));
  };

  const getAllImageUrls = () => imageUrls;

  return (
    <ImageUrlContext.Provider value={{ imageUrls, updateImageUrl, getAllImageUrls }}>
      {children}
    </ImageUrlContext.Provider>
  );
};