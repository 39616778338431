import { useState } from 'react';
import { useTokens } from '../contexts/TokenContext';
import { TOKEN_COSTS } from '../config/tokenCosts';

interface UseVisionGenerationProps {
  briefSummary: string;
  selectedLanguage: string;
  onVisionGenerated: (vision: string) => void;
}

export function useVisionGeneration({ 
  briefSummary, 
  selectedLanguage,
  onVisionGenerated 
}: UseVisionGenerationProps) {
  const [isGenerating, setIsGenerating] = useState(false);
  const { deductTokens, checkTokens } = useTokens();

  const generateVision = async () => {
    if (!briefSummary.trim()) {
      throw new Error('Brief summary is required for vision generation');
    }

    if (!checkTokens(TOKEN_COSTS.VISION_GENERATION)) {
      throw new Error('Insufficient tokens');
    }

    setIsGenerating(true);
    try {
      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK2, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          briefSummary: briefSummary.trim(),
          language: selectedLanguage
        })
      });

      if (!response.ok) {
        throw new Error('Vision generation failed');
      }

      const text = await response.text();
      if (!text.trim()) {
        throw new Error('Received empty response from vision generation');
      }

      await deductTokens(TOKEN_COSTS.VISION_GENERATION);
      onVisionGenerated(text);
    } catch (error) {
      setIsGenerating(false);
      throw error;
    }
    setIsGenerating(false);
  };

  return {
    isGenerating,
    generateVision
  };
}