import React from 'react';
import { FileDown } from 'lucide-react';

interface DownloadButtonsProps {
  pdfUrl: string;
  pptxUrl: string;
  title: string;
}

export default function DownloadButtons({ pdfUrl, pptxUrl }: DownloadButtonsProps) {
  return (
    <div className="flex space-x-4">
      <a
        href={pdfUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`flex items-center space-x-2 px-4 py-2 rounded-md text-white font-medium
          ${!pdfUrl ? 'bg-gray-400 cursor-not-allowed' : 'bg-primary-600 hover:bg-primary-700'}`}
      >
        <FileDown className="h-5 w-5" />
        <span>Download PDF</span>
      </a>

      <a
        href={pptxUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={`flex items-center space-x-2 px-4 py-2 rounded-md text-white font-medium
          ${!pptxUrl ? 'bg-gray-400 cursor-not-allowed' : 'bg-secondary-600 hover:bg-secondary-700'}`}
      >
        <FileDown className="h-5 w-5" />
        <span>Download PPTX</span>
      </a>
    </div>
  );
}