import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTokens } from '../contexts/TokenContext';
import { useWebhook } from '../contexts/WebhookContext';
import { useTreatmentStorage, Treatment } from '../hooks/useTreatmentStorage';
import { FileText, Plus, Coins, AlertCircle, Loader2, Trash2, User, LogOut } from 'lucide-react';
import DeleteTreatmentModal from '../components/DeleteTreatmentModal';
import UserDashboardModal from '../components/UserDashboardModal';
import { checkAndUpdateSubscription } from '../utils/subscription';

export default function Dashboard() {
  const navigate = useNavigate();
  const { user, userProfile, logout } = useAuth();
  const { tokens, loading: tokensLoading, error: tokensError } = useTokens();
  const { sendWebhookIfNeeded } = useWebhook();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showUserDashboard, setShowUserDashboard] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedTreatment, setSelectedTreatment] = useState<Treatment | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const { getTreatments, deleteTreatment } = useTreatmentStorage();

  useEffect(() => {
    const init = async () => {
      try {
        // Check if returning from subscription management
        const returnFromSubscription = sessionStorage.getItem('returnFromSubscription');
        if (returnFromSubscription && user && userProfile) {
          // Remove the flag
          sessionStorage.removeItem('returnFromSubscription');
          
          // Check and update subscription status
          await checkAndUpdateSubscription(user.uid, userProfile);
        }

        await sendWebhookIfNeeded();
        const loadedTreatments = await getTreatments();
        setTreatments(loadedTreatments);
      } catch (error) {
        setError('Failed to load treatments');
        console.error('Error loading treatments:', error);
      } finally {
        setIsLoading(false);
      }
    };

    init();
  }, [sendWebhookIfNeeded, getTreatments, user, userProfile]);

  const handleDelete = async () => {
    if (!selectedTreatment) return;

    setIsDeleting(true);
    try {
      await deleteTreatment(
        selectedTreatment.id,
        selectedTreatment.pdfUrl,
        selectedTreatment.pptxUrl
      );
      setTreatments(treatments.filter(t => t.id !== selectedTreatment.id));
    } catch (error) {
      setError('Failed to delete treatment');
      console.error('Error deleting treatment:', error);
    } finally {
      setIsDeleting(false);
      setShowDeleteModal(false);
      setSelectedTreatment(null);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
      setError('Failed to logout. Please try again.');
    }
  };

  if (isLoading || tokensLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-primary-600" />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <FileText className="h-8 w-8 text-primary-500" />
              <span className="ml-2 text-2xl font-bold text-gray-900">Dashboard</span>
            </div>
            <div className="flex items-center space-x-4">
              <div className="flex items-center bg-secondary-50 rounded-full px-4 py-1">
                <Coins className="h-4 w-4 text-secondary-500 mr-2" />
                <span className="text-sm font-medium text-secondary-700">
                  {tokens} tokens
                </span>
              </div>
              {tokensError && (
                <div className="flex items-center text-red-600">
                  <AlertCircle className="h-4 w-4 mr-1" />
                  <span className="text-sm">{tokensError}</span>
                </div>
              )}
              <button
                onClick={() => setShowUserDashboard(true)}
                className="flex items-center space-x-2 px-3 py-2 text-gray-700 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors"
              >
                <User className="h-4 w-4" />
                <span className="text-sm">{user?.email}</span>
              </button>
              <button
                onClick={handleLogout}
                className="flex items-center space-x-2 px-3 py-2 text-red-600 hover:text-red-700 rounded-md hover:bg-red-50 transition-colors"
              >
                <LogOut className="h-4 w-4" />
                <span className="text-sm">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="pt-24 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center text-red-700">
              <AlertCircle className="h-5 w-5 mr-2" />
              <span>{error}</span>
            </div>
          )}

          <div className="mb-8 flex justify-between items-center">
            <h2 className="text-2xl font-bold text-gray-900">Your Treatments</h2>
            <button
              onClick={() => navigate('/create-treatment')}
              className="flex items-center space-x-2 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors"
            >
              <Plus className="h-5 w-5" />
              <span>New Treatment</span>
            </button>
          </div>

          {treatments.length === 0 ? (
            <div className="text-center py-12 bg-white rounded-lg shadow">
              <FileText className="h-12 w-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium text-gray-900 mb-2">No treatments yet</h3>
              <p className="text-gray-500 mb-4">Create your first treatment to get started</p>
              <button
                onClick={() => navigate('/create-treatment')}
                className="inline-flex items-center space-x-2 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
              >
                <Plus className="h-5 w-5" />
                <span>Create Treatment</span>
              </button>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {treatments.map((treatment) => (
                <div
                  key={treatment.id}
                  className="bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow"
                >
                  <div className="p-6">
                    <div className="flex justify-between items-start mb-4">
                      <h3 className="text-lg font-semibold text-gray-900 line-clamp-2">
                        {treatment.title}
                      </h3>
                      <button
                        onClick={() => {
                          setSelectedTreatment(treatment);
                          setShowDeleteModal(true);
                        }}
                        className="p-1 text-gray-400 hover:text-red-500 rounded-full hover:bg-gray-100"
                      >
                        <Trash2 className="h-5 w-5" />
                      </button>
                    </div>
                    <p className="text-sm text-gray-500 mb-4">
                      Created: {treatment.createdAt.toLocaleDateString()}
                    </p>
                    <div className="flex space-x-3">
                      <a
                        href={treatment.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-1 px-3 py-2 bg-primary-100 text-primary-700 rounded-md hover:bg-primary-200 text-center text-sm"
                      >
                        View PDF
                      </a>
                      <a
                        href={treatment.pptxUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex-1 px-3 py-2 bg-secondary-100 text-secondary-700 rounded-md hover:bg-secondary-200 text-center text-sm"
                      >
                        Download PPTX
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <DeleteTreatmentModal
        isOpen={showDeleteModal}
        onClose={() => {
          setShowDeleteModal(false);
          setSelectedTreatment(null);
        }}
        onConfirm={handleDelete}
        isDeleting={isDeleting}
      />

      <UserDashboardModal
        isOpen={showUserDashboard}
        onClose={() => setShowUserDashboard(false)}
      />
    </div>
  );
}
