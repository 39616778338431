import { WebhookResponse } from '../types/webhook';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { auth } from '../config/firebase';
import { PLAN_TOKENS } from '../config/plans';

// Map price IDs to plan names
const PRICE_TO_PLAN = {
  'price_1QTOoTG3oXPUVempCtNgHmeX': 'Basic Plan',
  'price_1QTOoyG3oXPUVemp2ECjC33i': 'Pro Plan'
};

export async function sendUserWebhook(fullName: string, email: string, stripeCustomerId: string | null = null) {
  if (!auth.currentUser) {
    console.log('No authenticated user found');
    return;
  }

  try {
    console.log('Sending webhook 9 for user:', email);
    
    const response = await fetch('https://hook.eu2.make.com/xja87v35fwgbisldv9asqtyu28iawvfq', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fullName,
        email,
        stripeCustomerId
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send webhook');
    }

    const priceId = (await response.text()).trim();
    console.log('Received price ID:', priceId);
    
    const userRef = doc(db, 'users', auth.currentUser.uid);
    const userDoc = await getDoc(userRef);

    if (userDoc.exists()) {
      const currentData = userDoc.data();
      const currentPlan = currentData.currentPlan || 'free';
      
      // Determine new plan based on price ID
      const newPlan = PRICE_TO_PLAN[priceId as keyof typeof PRICE_TO_PLAN] || 'free';
      
      // Only update if new plan is different or higher tier
      if (newPlan !== currentPlan && 
          (newPlan === 'Pro Plan' || (newPlan === 'Basic Plan' && currentPlan === 'free'))) {
        const planTokens = newPlan === 'Basic Plan' ? PLAN_TOKENS.basic :
                          newPlan === 'Pro Plan' ? PLAN_TOKENS.pro :
                          PLAN_TOKENS.free;

        console.log('Upgrading to plan:', newPlan);
        console.log('Setting tokens to:', planTokens);
        
        await updateDoc(userRef, {
          currentPlan: newPlan,
          tokens: planTokens,
          lastTokenReset: new Date(),
          updatedAt: new Date()
        });
      }
    }
  } catch (error) {
    console.error('Webhook error:', error);
    throw error;
  }
}

export async function sendSignupWebhook(fullName: string, email: string): Promise<string> {
  try {
    console.log('Sending webhook 8 for signup:', email);
    
    const response = await fetch('https://hook.eu2.make.com/56fegbwoq0hnqre0220dinfgp7ta6uea', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fullName,
        email
      })
    });

    if (!response.ok) {
      throw new Error('Failed to send webhook');
    }

    const data = await response.text();
    const stripeId = data.replace('StripeID:', '').trim();
    
    if (!stripeId) {
      throw new Error('Invalid stripe ID received');
    }

    console.log('Received Stripe ID:', stripeId);
    return stripeId;
  } catch (error) {
    console.error('Webhook error:', error);
    throw error;
  }
}