import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Video, Check, ArrowRight, CreditCard } from 'lucide-react';

export default function Pricing() {
  const navigate = useNavigate();

  // Scroll to top when component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const plans = [
    {
      name: 'Free',
      price: '$0',
      tokens: '500',
      description: 'Perfect for trying out RealTreat and creating your first treatment.',
      features: [
        '1 treatment per month',
        'Limited image re-generation',
        'Unlimited image uploads',
        'Basic templates',
        'Standard support'
      ]
    },
    {
      name: 'Basic Plan',
      price: '$25',
      tokens: '2,500',
      description: 'Ideal for directors working on multiple projects monthly.',
      features: [
        'Up to 5 treatments per month',
        'More flexible image regeneration',
        'Unlimited image uploads',
        'Access to all templates',
        'Priority support'
      ]
    },
    {
      name: 'Pro Plan',
      price: '$49',
      tokens: '6,000',
      description: 'For professionals who need maximum flexibility and resources.',
      features: [
        'Up to 12 treatments per month',
        'Flexible image re-generations',
        'Unlimited image uploads',
        'Access to all templates',
        'Priority support',
        'Early access to new features'
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div 
                className="flex items-center space-x-2 cursor-pointer" 
                onClick={() => navigate('/')}
              >
                <Video className="h-8 w-8 text-primary-500" />
                <span className="text-2xl font-bold text-gray-900">RealTreat</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/login')}
                className="px-4 py-2 text-gray-700 hover:text-gray-900"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="pt-24 pb-16 text-center px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-6">
          Simple, Transparent Pricing
        </h1>
        <p className="max-w-2xl mx-auto text-xl text-gray-600">
          Start for free and upgrade when you need more. All plans include access to our AI assistant, 
          image generation, and professional templates.
        </p>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {plans.map((plan) => (
            <div
              key={plan.name}
              className={`rounded-lg shadow-lg overflow-hidden bg-white ${
                plan.name === 'Basic Plan' ? 'ring-2 ring-primary-500 transform scale-105' : ''
              }`}
            >
              <div className="px-6 py-8">
                <div className="flex justify-between items-center">
                  <h3 className="text-2xl font-semibold text-gray-900">{plan.name}</h3>
                  {plan.name === 'Basic Plan' && (
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-primary-100 text-primary-800">
                      Most Popular
                    </span>
                  )}
                </div>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">{plan.price}</span>
                  <span className="text-base font-medium text-gray-500">/month</span>
                </p>
                <p className="mt-2 text-sm text-gray-500">{plan.tokens} tokens per month</p>
                <p className="mt-4 text-sm text-gray-600">{plan.description}</p>

                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className="h-5 w-5 text-green-500" />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white/40 backdrop-blur-sm py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <div className="bg-white rounded-2xl p-8 shadow-lg">
            <div className="flex justify-center mb-6">
              <CreditCard className="h-12 w-12 text-primary-500" />
            </div>
            <h2 className="text-3xl font-bold text-gray-900 mb-4">
              Start Creating Today
            </h2>
            <p className="text-lg text-gray-600 mb-8">
              Begin with our free plan and upgrade anytime from your dashboard when you need more. 
              No credit card required to start.
            </p>
            <button
              onClick={() => navigate('/login')}
              className="inline-flex items-center px-8 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transform hover:scale-105 transition"
            >
              Start for Free
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}