export function parseStyleDescriptors(text: string): string[] {
  if (!text?.trim()) return [];

  // Split by semicolon and process each descriptor
  return text
    .split(';')
    .map(descriptor => {
      // Remove quotes and trim whitespace
      return descriptor
        .trim()
        .replace(/^["'](.*)["']$/, '$1') // Remove surrounding quotes
        .replace(/^["\s]+|["\s]+$/g, ''); // Remove any remaining quotes and whitespace
    })
    .filter(Boolean); // Remove empty entries
}