import { doc, runTransaction } from 'firebase/firestore';
import { db } from '../../config/firebase';

export class TokenDeductionError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);
    this.name = 'TokenDeductionError';
  }
}

export async function deductTokensWithRetry(
  userId: string, 
  amount: number, 
  maxRetries = 3
): Promise<boolean> {
  let attempts = 0;

  while (attempts < maxRetries) {
    try {
      const success = await runTransaction(db, async (transaction) => {
        const userRef = doc(db, 'users', userId);
        const userDoc = await transaction.get(userRef);

        if (!userDoc.exists()) {
          throw new TokenDeductionError('User not found', 'user_not_found');
        }

        const currentTokens = userDoc.data().tokens || 0;
        if (currentTokens < amount) {
          return false;
        }

        transaction.update(userRef, {
          tokens: currentTokens - amount,
          updatedAt: new Date()
        });

        return true;
      });

      return success;
    } catch (error) {
      attempts++;
      if (attempts === maxRetries) {
        console.error('Token deduction failed after max retries:', error);
        throw new TokenDeductionError(
          'Failed to deduct tokens',
          'deduction_failed'
        );
      }
      // Wait before retrying (exponential backoff)
      await new Promise(resolve => setTimeout(resolve, Math.pow(2, attempts) * 100));
    }
  }

  return false;
}