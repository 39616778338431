import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Check, Loader2 } from 'lucide-react';
import { useTreatmentStorage } from '../hooks/useTreatmentStorage';
import { extractFileUrls } from '../utils/downloadUtils';

interface FinishButtonProps {
  finalResponse: string;
  title: string;
  isLoading?: boolean;
}

export default function FinishButton({ finalResponse, title, isLoading }: FinishButtonProps) {
  const navigate = useNavigate();
  const { saveTreatment } = useTreatmentStorage();
  const [isSaving, setIsSaving] = React.useState(false);

  const handleFinish = async () => {
    setIsSaving(true);
    try {
      const { pdfUrl, pptxUrl } = extractFileUrls(finalResponse);
      
      await saveTreatment({
        title,
        pdfUrl,
        pptxUrl,
        createdAt: new Date(),
        updatedAt: new Date()
      });

      navigate('/dashboard');
    } catch (error) {
      console.error('Error finishing treatment:', error);
      alert('Failed to save treatment. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <button
      onClick={handleFinish}
      disabled={isLoading || isSaving}
      className={`px-6 py-2 rounded-md text-white font-medium w-32 text-center
        ${isLoading || isSaving
          ? 'bg-primary-300 cursor-not-allowed'
          : 'bg-primary-600 hover:bg-primary-700'
        }
        flex items-center justify-center space-x-2`}
    >
      {isLoading || isSaving ? (
        <>
          <Loader2 className="h-5 w-5 animate-spin" />
          <span>Saving...</span>
        </>
      ) : (
        <>
          <Check className="h-5 w-5" />
          <span>Finish</span>
        </>
      )}
    </button>
  );
}