import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Video, Wand2, Zap, Shield, Users, Palette } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';

export default function Landing() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const handleStartFree = () => {
    sessionStorage.setItem('freeTrial', 'true');
    navigate('/login');
  };

  const features = [
    {
      icon: <Users className="h-6 w-6 text-primary-500" />,
      title: 'Upload & Unload',
      description: 'Drag in that agency brief and let out a sigh of relief. RealTreat does the heavy lifting of decoding and interpreting.',
    },
    {
      icon: <Wand2 className="h-6 w-6 text-primary-500" />,
      title: 'Spark Your Concept',
      description: 'Got a lightning-bolt idea? Type it quick! No idea yet? RealTreat\'s AI muse is ready to brainstorm with you – think of it like a creative espresso shot for your imagination.',
    },
    {
      icon: <Palette className="h-6 w-6 text-primary-500" />,
      title: 'Style It Your Way',
      description: 'Choose a presentation template that screams "THIS IS ME!" From slick and minimalist to bold and quirky, RealTreat \'s got layouts that \'ll make your clients sit up and take notice.',
    },
    {
      icon: <Shield className="h-6 w-6 text-primary-500" />,
      title: 'Craft & Control',
      description: 'This isn\'t about robot overlords taking over. RealTreat is about you, the director, having an intelligent assistant that knows when to lead and when to follow.',
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div className="flex items-center space-x-2">
                <Video className="h-8 w-8 text-primary-500" />
                <span className="text-2xl font-bold text-gray-900">RealTreat</span>
              </div>
            </div>
            <div className="flex items-center">
              <button
                onClick={() => navigate('/login')}
                className="px-4 py-2 text-gray-700 hover:text-gray-900"
              >
                Log in
              </button>
            </div>
          </div>
        </div>
      </nav>

      <div className="pt-24 pb-16 text-center px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <h1 className="text-4xl sm:text-5xl font-extrabold text-gray-900 tracking-tight mb-8">
            Create Stunning Director's Treatments in Minutes
          </h1>
          <p className="text-xl text-gray-600 mb-10">
            As a director, you know how it goes—weekends lost to creating treatments that never seem to end. The pressure to nail every detail, meet every deadline. What if you could skip the stress and focus on what you do best?

With RealTreat, you can. 
          </p>
          <div className="flex justify-center space-x-4">
            <button
              onClick={handleStartFree}
              className="px-8 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transform hover:scale-105 transition"
            >
              Start for Free
            </button>
            <button
              onClick={() => navigate('/learn-more')}
              className="px-8 py-3 bg-white text-primary-600 rounded-md hover:bg-gray-50 transform hover:scale-105 transition"
            >
              Learn More
            </button>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white/80 backdrop-blur-sm rounded-xl p-6 shadow-sm hover:shadow-md transition"
            >
              <div className="flex items-center space-x-4">
                <div className="flex-shrink-0">
                  {feature.icon}
                </div>
                <div>
                  <h3 className="text-lg font-semibold text-gray-900">{feature.title}</h3>
                  <p className="mt-1 text-gray-600">{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="bg-white/40 backdrop-blur-sm py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">
            How It Works
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="text-center">
              <div className="bg-primary-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-primary-600 font-bold">1</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Upload Agency Brief</h3>
              <p className="text-gray-600">Based on the agency brief, our assistant will set project up for you in seconds.</p>
            </div>
            <div className="text-center">
              <div className="bg-primary-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-primary-600 font-bold">2</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Work with our assistant</h3>
              <p className="text-gray-600">Briefly and loosly tell AI asistant what is your idea, and curate the answers, images, and presentation templates.</p>
            </div>
            <div className="text-center">
              <div className="bg-primary-100 rounded-full w-12 h-12 flex items-center justify-center mx-auto mb-4">
                <span className="text-primary-600 font-bold">3</span>
              </div>
              <h3 className="text-xl font-semibold mb-2">Export & Share</h3>
              <p className="text-gray-600">Download PDF or share your treatment with clients. Or edit it further in Google Slides, PowerPaint or Keynote.</p>
            </div>
          </div>
        </div>
      </div>

      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            Ready to Create Your First Treatment?
          </h2>
          <button
            onClick={handleStartFree}
            className="px-8 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transform hover:scale-105 transition"
          >
            Start for Free
          </button>
        </div>
      </div>
    </div>
  );
}