import React, { useEffect, useState } from 'react';
import { templates, Template } from '../data/templates';
import { Check, ChevronDown } from 'lucide-react';

interface TemplateSelectorProps {
  selectedTemplate: Template | null;
  onTemplateSelect: (template: Template) => void;
}

export default function TemplateSelector({ selectedTemplate, onTemplateSelect }: TemplateSelectorProps) {
  const [hoveredTemplate, setHoveredTemplate] = useState<number | null>(null);
  const [imageIndexes, setImageIndexes] = useState<{ [key: number]: number }>({});
  const [showAll, setShowAll] = useState(false);
  const [preloadedImages, setPreloadedImages] = useState<Set<string>>(new Set());
  
  // Select first template by default
  useEffect(() => {
    if (!selectedTemplate) {
      onTemplateSelect(templates[0]);
    }
  }, []);

  // Preload all template images
  useEffect(() => {
    const preloadImage = (url: string) => {
      if (preloadedImages.has(url)) return;

      const img = new Image();
      img.onload = () => {
        setPreloadedImages(prev => new Set([...prev, url]));
      };
      img.src = url;
    };

    templates.forEach(template => {
      template.images.forEach(preloadImage);
    });
  }, []);

  // Setup image rotation only for hovered template
  useEffect(() => {
    if (hoveredTemplate === null) return;

    const interval = setInterval(() => {
      setImageIndexes(prev => ({
        ...prev,
        [hoveredTemplate]: ((prev[hoveredTemplate] || 0) + 1) % templates.find(t => t.id === hoveredTemplate)!.images.length
      }));
    }, 2000);

    return () => clearInterval(interval);
  }, [hoveredTemplate]);

  const displayedTemplates = showAll ? templates : templates.slice(0, 3);

  return (
    <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">Select Presentation Template</h3>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {displayedTemplates.map((template) => (
          <div
            key={template.id}
            className={`relative cursor-pointer ${
              selectedTemplate?.id === template.id
                ? 'ring-2 ring-primary-500'
                : 'hover:ring-2 hover:ring-primary-200'
            } rounded-lg overflow-hidden transition-all duration-200`}
            onClick={() => onTemplateSelect(template)}
            onMouseEnter={() => setHoveredTemplate(template.id)}
            onMouseLeave={() => setHoveredTemplate(null)}
          >
            <div className="p-3 bg-gray-50 border-b border-gray-200">
              <h4 className="text-center font-medium text-gray-900">{template.name}</h4>
            </div>
            <div className="relative aspect-video">
              {template.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`${template.name} preview ${index + 1}`}
                  className={`absolute inset-0 w-full h-full object-cover transition-opacity duration-500 ${
                    hoveredTemplate === template.id && imageIndexes[template.id] === index
                      ? 'opacity-100'
                      : index === 0 && hoveredTemplate !== template.id
                      ? 'opacity-100'
                      : 'opacity-0'
                  }`}
                  loading="eager"
                />
              ))}
              {selectedTemplate?.id === template.id && (
                <div className="absolute top-2 right-2 bg-primary-500 text-white p-1 rounded-full z-10">
                  <Check className="h-4 w-4" />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
      
      {templates.length > 3 && (
        <div className="mt-6 text-center">
          <button
            onClick={() => setShowAll(!showAll)}
            className="inline-flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200 transition-colors"
          >
            <span>{showAll ? 'Show Less' : 'Show More'}</span>
            <ChevronDown className={`h-4 w-4 transition-transform ${showAll ? 'rotate-180' : ''}`} />
          </button>
        </div>
      )}
    </div>
  );
}