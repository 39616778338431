import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { TokenProvider } from './contexts/TokenContext';
import { ImageUrlProvider } from './contexts/ImageUrlContext';
import { TemplateProvider } from './contexts/TemplateContext';
import { WebhookProvider } from './contexts/WebhookContext';
import Landing from './pages/Landing';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Wizard from './pages/Wizard';
import LearnMore from './pages/LearnMore';
import Pricing from './pages/Pricing';
import PaymentSuccess from './pages/PaymentSuccess';
import ErrorPage from './pages/ErrorPage';
import ManageSubscriptionPage from './pages/ManageSubscriptionPage';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }
  
  return user ? <>{children}</> : <Navigate to="/login" replace />;
};

const PublicRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-primary-600"></div>
      </div>
    );
  }
  
  return !user ? <>{children}</> : <Navigate to="/dashboard" replace />;
};

export default function App() {
  return (
    <AuthProvider>
      <WebhookProvider>
        <TokenProvider>
          <ImageUrlProvider>
            <TemplateProvider>
              <BrowserRouter>
                <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
                  <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/login" element={<PublicRoute><Login /></PublicRoute>} />
                    <Route path="/learn-more" element={<LearnMore />} />
                    <Route path="/pricing" element={<Pricing />} />
                    <Route path="/payment-success" element={<PaymentSuccess />} />
                    <Route
                      path="/dashboard"
                      element={
                        <PrivateRoute>
                          <Dashboard />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/manage-subscription"
                      element={
                        <PrivateRoute>
                          <ManageSubscriptionPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path="/create-treatment"
                      element={
                        <PrivateRoute>
                          <Wizard />
                        </PrivateRoute>
                      }
                    />
                    <Route path="/404" element={<ErrorPage />} />
                    <Route path="*" element={<Navigate to="/404" replace />} />
                  </Routes>
                </div>
              </BrowserRouter>
            </TemplateProvider>
          </ImageUrlProvider>
        </TokenProvider>
      </WebhookProvider>
    </AuthProvider>
  );
}