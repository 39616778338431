import { uploadFile } from '@uploadcare/upload-client';

export async function uploadToUploadcare(file: File): Promise<string> {
  try {
    const result = await uploadFile(file, {
      publicKey: '6ed4dd3601e7b4d0d8d6',
      store: 'auto',
      metadata: {
        subsystem: 'uploader',
        treatment: 'true'
      }
    });

    if (!result?.cdnUrl) {
      throw new Error('Upload failed - no CDN URL received');
    }

    return result.cdnUrl;
  } catch (error) {
    console.error('Uploadcare upload error:', error);
    throw new Error('Failed to upload image to Uploadcare');
  }
}