import React, { useState, useRef } from 'react';
import { Mic, MicOff, Loader2 } from 'lucide-react';
import RecordRTC from 'recordrtc';
import { openai } from '../config/openai';

interface VoiceInputProps {
  onTranscriptionComplete: (text: string) => void;
}

export default function VoiceInput({ onTranscriptionComplete }: VoiceInputProps) {
  const [isRecording, setIsRecording] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const recorderRef = useRef<RecordRTC | null>(null);
  const streamRef = useRef<MediaStream | null>(null);

  const startRecording = async () => {
    try {
      setError(null);
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      
      recorderRef.current = new RecordRTC(stream, {
        type: 'audio',
        mimeType: 'audio/webm',
        recorderType: RecordRTC.StereoAudioRecorder,
        numberOfAudioChannels: 1,
        desiredSampRate: 16000
      });

      recorderRef.current.startRecording();
      setIsRecording(true);
    } catch (err) {
      setError('Unable to access microphone. Please check your permissions.');
      console.error('Recording error:', err);
    }
  };

  const stopRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(async () => {
        const blob = await recorderRef.current?.getBlob();
        if (blob) {
          await processAudio(blob);
        }
        
        // Clean up
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
          streamRef.current = null;
        }
        recorderRef.current = null;
      });
      setIsRecording(false);
    }
  };

  const processAudio = async (audioBlob: Blob) => {
    setIsProcessing(true);
    setError(null);

    try {
      const response = await openai.audio.transcriptions.create({
        file: new File([audioBlob], 'audio.webm', { type: 'audio/webm' }),
        model: 'whisper-1',
      });

      if (response.text) {
        onTranscriptionComplete(response.text);
      } else {
        throw new Error('No transcription received');
      }
    } catch (err) {
      console.error('Transcription error:', err);
      setError('Failed to transcribe audio. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="flex items-center justify-end space-x-3">
      {error && (
        <span className="text-sm text-red-600">{error}</span>
      )}
      {isRecording && (
        <span className="text-sm text-primary-600 animate-pulse">
          Recording... Click to stop
        </span>
      )}
      {isProcessing && (
        <span className="text-sm text-primary-600">
          Processing recording...
        </span>
      )}
      <button
        onClick={isRecording ? stopRecording : startRecording}
        disabled={isProcessing}
        className={`p-3 rounded-full transition-colors flex-shrink-0 ${
          isRecording
            ? 'bg-red-100 hover:bg-red-200 text-red-600'
            : 'bg-primary-100 hover:bg-primary-200 text-primary-600'
        } ${isProcessing ? 'opacity-50 cursor-not-allowed' : ''}`}
      >
        {isProcessing ? (
          <Loader2 className="h-6 w-6 animate-spin" />
        ) : isRecording ? (
          <MicOff className="h-6 w-6" />
        ) : (
          <Mic className="h-6 w-6" />
        )}
      </button>
    </div>
  );
}