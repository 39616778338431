import React, { useState } from 'react';
import SlideVisuals from './SlideVisuals';
import TemplateSelector from './TemplateSelector';
import DownloadButton from './DownloadButton';
import LoadingOverlay from './LoadingOverlay';
import { useVisualsManager } from '../hooks/useVisualsManager';
import { useTemplate } from '../contexts/TemplateContext';
import { downloadAllImages } from '../utils/downloadUtils';
import type { Template } from '../data/templates';

interface VisualsDisplayProps {
  visualsResponse: string;
}

export default function VisualsDisplay({ visualsResponse }: VisualsDisplayProps) {
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const {
    managedSlides,
    editablePrompts,
    isGenerating,
    handleDeleteImage,
    handlePromptChange,
    handleFileUpload,
    handleGenerateNew
  } = useVisualsManager(visualsResponse);

  const { selectedTemplate, setSelectedTemplate } = useTemplate();

  const handleTemplateSelect = (template: Template) => {
    setSelectedTemplate(template);
  };

  const handleDownloadAll = async () => {
    setIsDownloadingAll(true);
    try {
      const images = managedSlides.flatMap((slide) =>
        slide.images
          .filter((img) => !img.isDeleted && img.link)
          .map((img, index) => ({
            url: img.link,
            filename: `slide-${slide.slideNumber}-image-${index + 1}.jpg`
          }))
      );

      await downloadAllImages(images);
    } catch (error) {
      alert('Failed to download images. Please try again.');
    } finally {
      setIsDownloadingAll(false);
    }
  };

  if (!visualsResponse) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6">
        <h3 className="text-xl font-semibold text-gray-900 mb-4">No visuals generated yet</h3>
      </div>
    );
  }

  return (
    <>
      <LoadingOverlay 
        isVisible={isGenerating} 
        message="Generating new image..."
      />
      
      <div className="space-y-8">
        <TemplateSelector
          selectedTemplate={selectedTemplate}
          onTemplateSelect={handleTemplateSelect}
        />
        {managedSlides.map((slide) => (
          <SlideVisuals
            key={slide.slideNumber}
            slideNumber={slide.slideNumber}
            images={slide.images}
            editablePrompts={editablePrompts}
            onDeleteImage={(imageIndex, dimensions) => handleDeleteImage(slide.slideNumber, imageIndex, dimensions)}
            onPromptChange={(imageIndex, newPrompt) => handlePromptChange(slide.slideNumber, imageIndex, newPrompt)}
            onFileUpload={(imageIndex, file) => handleFileUpload(slide.slideNumber, imageIndex, file)}
            onGenerateNew={(imageIndex) => handleGenerateNew(slide.slideNumber, imageIndex)}
          />
        ))}
        <div className="flex justify-center pb-8">
          <DownloadButton
            onClick={handleDownloadAll}
            isProcessing={isDownloadingAll}
            label="Download All Images"
            className="px-6 py-2 text-base"
          />
        </div>
      </div>
    </>
  );
}