import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  User,
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { doc, setDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { PLAN_TOKENS } from '../config/plans';
import { sendSignupWebhook } from '../utils/webhook';

interface UserProfile {
  email: string;
  fullName: string;
  createdAt: Date;
  updatedAt: Date;
  tokens: number;
  currentPlan: keyof typeof PLAN_TOKENS;
  subscriptionStatus?: string;
  stripeCustomerId?: string;
  stripeSubscriptionId?: string;
}

interface AuthContextType {
  user: User | null;
  userProfile: UserProfile | null;
  loading: boolean;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  signup: (email: string, password: string, fullName: string) => Promise<void>;
  logout: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      setLoading(true);

      if (user) {
        const userRef = doc(db, 'users', user.uid);
        
        try {
          // First, check if user document exists
          const userDoc = await getDoc(userRef);
          
          if (!userDoc.exists()) {
            // Create initial user profile if it doesn't exist
            const initialProfile = {
              email: user.email,
              fullName: user.displayName || '',
              createdAt: new Date(),
              updatedAt: new Date(),
              lastTokenReset: new Date(),
              tokens: PLAN_TOKENS.free,
              currentPlan: 'free' as const,
              subscriptionStatus: 'none'
            };
            
            await setDoc(userRef, initialProfile);
          }

          // Set up real-time listener for user profile
          const unsubscribeProfile = onSnapshot(userRef, (doc) => {
            if (doc.exists()) {
              const data = doc.data();
              const profile = {
                ...data,
                createdAt: data.createdAt.toDate(),
                updatedAt: data.updatedAt.toDate(),
                lastTokenReset: data.lastTokenReset?.toDate(),
              } as UserProfile;
              
              setUserProfile(profile);
            }
            setLoading(false);
          }, (error) => {
            console.error('Error fetching user profile:', error);
            setLoading(false);
          });

          return () => unsubscribeProfile();
        } catch (error) {
          console.error('Error setting up profile listener:', error);
          setLoading(false);
        }
      } else {
        setUserProfile(null);
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const signup = async (email: string, password: string, fullName: string) => {
    try {
      // First, send webhook to create Stripe customer
      const stripeCustomerId = await sendSignupWebhook(fullName, email);

      // Create Firebase user
      const result = await createUserWithEmailAndPassword(auth, email, password);
      
      // Create initial user profile
      const now = new Date();
      await setDoc(doc(db, 'users', result.user.uid), {
        email,
        fullName,
        createdAt: now,
        updatedAt: now,
        lastTokenReset: now,
        tokens: PLAN_TOKENS.free,
        currentPlan: 'free',
        subscriptionStatus: 'none',
        stripeCustomerId
      });

    } catch (error) {
      if (auth.currentUser) {
        await auth.currentUser.delete();
      }
      throw error;
    }
  };

  const loginWithEmail = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const logout = async () => {
    await signOut(auth);
  };

  const value = {
    user,
    userProfile,
    loading,
    loginWithEmail,
    signup,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}
