export function extractCommercialTitle(briefSummary: string): string {
  if (!briefSummary) return 'Untitled Commercial';
  
  const titleMatch = briefSummary.match(/\[title\](.*?)\[\/title\]/);
  if (titleMatch && titleMatch[1]) {
    return titleMatch[1].trim();
  }
  return 'Untitled Commercial';
}

export function extractBriefSummary(briefSummary: string): string {
  if (!briefSummary) return '\n';
  
  // Remove the title section and add an empty line at the beginning
  const summary = briefSummary.replace(/\[title\].*?\[\/title\]/, '').trim();
  return summary ? `\n${summary}` : '\n';
}