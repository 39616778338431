import { useState, useCallback } from 'react';
import { getErrorMessage } from '../utils/errorHandling';

export function useErrorHandling() {
  const [error, setError] = useState<string | null>(null);

  const handleError = useCallback((error: unknown) => {
    const message = getErrorMessage(error);
    setError(message);
    console.error(message);
  }, []);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return {
    error,
    handleError,
    clearError
  };
}