import React, { useState } from 'react';
import { Upload, FileCheck, AlertCircle, Loader2 } from 'lucide-react';
import { uploadFile } from '@uploadcare/upload-client';

interface BriefUploadProps {
  onUploadComplete: (fileUrl: string, isPdf: boolean) => void;
}

const ALLOWED_TYPES = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'application/vnd.oasis.opendocument.text',
  'application/vnd.oasis.opendocument.spreadsheet',
  'application/vnd.oasis.opendocument.presentation',
  'text/plain'
];

const FILE_TYPE_NAMES: { [key: string]: string } = {
  'application/pdf': 'PDF',
  'application/msword': 'DOC',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'DOCX',
  'application/vnd.ms-excel': 'XLS',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'XLSX',
  'application/vnd.ms-powerpoint': 'PPT',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PPTX',
  'application/rtf': 'RTF',
  'application/vnd.oasis.opendocument.text': 'ODT',
  'application/vnd.oasis.opendocument.spreadsheet': 'ODS',
  'application/vnd.oasis.opendocument.presentation': 'ODP',
  'text/plain': 'TXT'
};

export default function BriefUpload({ onUploadComplete }: BriefUploadProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isUploaded, setIsUploaded] = useState(false);

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = async (e: React.DragEvent) => {
    e.preventDefault();
    setIsDragging(false);
    const file = e.dataTransfer.files[0];
    await handleFileUpload(file);
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      await handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file: File) => {
    if (!ALLOWED_TYPES.includes(file.type)) {
      setError(`Unsupported file type. Please upload ${Object.values(FILE_TYPE_NAMES).join(', ')}`);
      return;
    }

    if (file.size > 100 * 1024 * 1024) {
      setError('File size must be less than 100MB');
      return;
    }

    setUploading(true);
    setError(null);
    setIsUploaded(false);

    try {
      const result = await uploadFile(file, {
        publicKey: '6ed4dd3601e7b4d0d8d6',
        store: 'auto',
        metadata: {
          subsystem: 'uploader',
          brief: 'true'
        }
      });

      if (!result.uuid) {
        throw new Error('Upload failed - no UUID received');
      }

      const fileUrl = result.cdnUrl || `https://ucarecdn.com/${result.uuid}/`;
      const isPdf = file.type === 'application/pdf';
      
      setIsUploaded(true);
      onUploadComplete(fileUrl, isPdf);
    } catch (err: any) {
      console.error('Upload error:', err);
      setError(
        err.message === 'Autostore is disabled.'
          ? 'File upload service is temporarily unavailable. Please try again later.'
          : 'Failed to upload file. Please try again.'
      );
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="w-full max-w-2xl mx-auto">
      <div
        className={`relative border-2 border-dashed rounded-lg p-8 text-center
          ${isDragging ? 'border-primary-500 bg-primary-50' : 'border-gray-300 bg-gray-50'}
          ${uploading ? 'opacity-50' : ''}
          transition-all duration-200`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <input
          type="file"
          accept={ALLOWED_TYPES.join(',')}
          onChange={handleFileChange}
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          disabled={uploading}
        />
        
        <div className="space-y-4">
          <div className="flex justify-center">
            {uploading ? (
              <Loader2 className="h-12 w-12 text-primary-500 animate-spin" />
            ) : isUploaded ? (
              <FileCheck className="h-12 w-12 text-green-500" />
            ) : (
              <Upload className="h-12 w-12 text-primary-500" />
            )}
          </div>
          
          <div className="text-gray-600">
            <p className="text-lg font-medium">
              {uploading ? 'Uploading...' :
               isUploaded ? 'File uploaded successfully!' :
               'Upload Agency Brief'}
            </p>
            {!isUploaded && (
              <>
                <p className="text-sm">
                  Drag and drop your file here, or click to browse
                </p>
                <p className="text-xs mt-2">
                  Supported formats: {Object.values(FILE_TYPE_NAMES).join(', ')}
                </p>
                <p className="text-xs mt-1">
                  Maximum file size: 100MB
                </p>
              </>
            )}
          </div>
        </div>
      </div>

      {error && (
        <div className="mt-4 p-3 bg-red-50 rounded-md flex items-center text-red-700">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <span className="text-sm">{error}</span>
        </div>
      )}
    </div>
  );
}