export interface Template {
  id: number;
  name: string;
  images: string[];
  filename: string;
}

export const templates: Template[] = [
  {
    id: 1,
    name: "Simple Template",
    images: [
      "https://ucarecdn.com/0ae3c2cf-98f3-4901-8aa7-992eebec0c96/Simple1.jpg",
      "https://ucarecdn.com/00a0d3bb-bbaa-47de-95bb-06c97eda7e39/SImple2.jpg",
      "https://ucarecdn.com/67ec3db3-739b-4690-8c8c-c47e4d5cf4e6/Simple3.jpg",
      "https://ucarecdn.com/c17a062f-f2b7-4ca3-9e97-3728aed45e0d/Simple4.jpg"
    ],
    filename: "1"
  },
  {
    id: 2,
    name: "Warm Glow",
    images: [
      "https://ucarecdn.com/7f7639ae-a692-4e2a-ab3e-b33b15034fcf/WarmGlow1.jpg",
      "https://ucarecdn.com/81a38b8e-ce67-4a65-a1c5-9606a093c9b0/WarmGlow2.jpg",
      "https://ucarecdn.com/601a0b9b-2845-4b62-b0a0-01d0f7177076/WarmGlow3.jpg",
      "https://ucarecdn.com/ce215ca2-6c19-4e8a-b648-4cc8199c01b1/WarmGlow4.jpg"
    ],
    filename: "2"
  },
  {
    id: 3,
    name: "Black&Yellow",
    images: [
      "https://ucarecdn.com/c286b4f4-9ab4-4e3c-a962-560d4b0c1a33/BlackYellow1.jpg",
      "https://ucarecdn.com/b339e08e-f7ae-4baf-9747-83904edf445d/BlackYellow2.jpg",
      "https://ucarecdn.com/5edaf9e1-eb79-4c39-be12-1b21f06e635f/BlackYellow3.jpg",
      "https://ucarecdn.com/e5dad74e-9705-45b4-a631-a8de7e85eb67/BlackYellow4.jpg"
    ],
    filename: "3"
  }
];