import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X, AlertCircle, Coins } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { getManageSubscriptionLink } from '../utils/subscription';

interface InsufficientTokensModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function InsufficientTokensModal({
  isOpen,
  onClose
}: InsufficientTokensModalProps) {
  const navigate = useNavigate();
  const { userProfile } = useAuth();
  const [isProcessing, setIsProcessing] = React.useState(false);

  if (!isOpen) return null;

  const handleManageTokens = async () => {
    try {
      setIsProcessing(true);
      const managementLink = await getManageSubscriptionLink(userProfile?.stripeCustomerId || null);
      
      // Store current URL in sessionStorage
      sessionStorage.setItem('returnFromSubscription', 'true');
      
      // Navigate to management link in same window
      window.location.href = managementLink;
      
      onClose(); // Close the modal before navigation
    } catch (error) {
      console.error('Error managing subscription:', error);
      alert('Failed to access subscription management. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-start mb-4">
          <div className="flex items-center">
            <AlertCircle className="h-6 w-6 text-red-500 mr-2" />
            <h2 className="text-xl font-semibold text-gray-900">Insufficient Tokens</h2>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <div className="mb-6">
          <p className="text-gray-600 mb-4">
            You don't have enough tokens to perform this action. Upgrade your plan or wait for your next token refresh.
          </p>
          <div className="bg-gray-50 p-4 rounded-md">
            <div className="flex items-center text-gray-700 mb-2">
              <Coins className="h-5 w-5 text-primary-500 mr-2" />
              <span className="font-medium">Current Plan: {userProfile?.currentPlan?.charAt(0).toUpperCase() + userProfile?.currentPlan?.slice(1) || 'Free'}</span>
            </div>
            <p className="text-sm text-gray-600">
              Upgrade to a higher plan to get more tokens and additional features.
            </p>
          </div>
        </div>

        <div className="flex flex-col space-y-3">
          <button
            onClick={handleManageTokens}
            disabled={isProcessing}
            className="w-full px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 flex items-center justify-center space-x-2"
          >
            {isProcessing ? (
              <>
                <span className="animate-spin">⌛</span>
                <span>Processing...</span>
              </>
            ) : (
              <>
                <Coins className="h-5 w-5 mr-2" />
                <span>Manage Subscription</span>
              </>
            )}
          </button>
          <button
            onClick={onClose}
            className="w-full px-4 py-2 bg-gray-100 text-gray-700 rounded-md hover:bg-gray-200"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}