import { loadStripe } from '@stripe/stripe-js';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';
import { PLAN_TOKENS } from '../config/plans';

// Get base URL for API calls
const API_URL = import.meta.env.PROD 
  ? '/.netlify/functions'
  : 'http://localhost:8888/.netlify/functions';

export async function createCheckoutSession(priceId: string, userId: string) {
  try {
    const response = await fetch(`${API_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId,
        userId,
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to create checkout session');
    }

    const { sessionId } = await response.json();
    const stripeInstance = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);
    
    if (!stripeInstance) {
      throw new Error('Stripe not initialized');
    }

    const { error } = await stripeInstance.redirectToCheckout({
      sessionId,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
}

export async function updateSubscriptionStatus(
  userId: string,
  status: string,
  plan: keyof typeof PLAN_TOKENS,
  periodEnd: Date
) {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    currentPlan: plan,
    subscriptionStatus: status,
    tokens: PLAN_TOKENS[plan],
    lastTokenReset: new Date(),
    subscriptionPeriodEnd: periodEnd,
    updatedAt: new Date(),
  });
}

export async function handleSubscriptionChange(
  userId: string,
  plan: 'basic' | 'pro',
  status: string
) {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    currentPlan: plan,
    subscriptionStatus: status,
    tokens: PLAN_TOKENS[plan],
    lastTokenReset: new Date(),
    updatedAt: new Date(),
  });
}