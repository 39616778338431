import { useState } from 'react';
import { TOKEN_COSTS } from '../config/tokenCosts';
import { useTokens } from '../contexts/TokenContext';
import { extractCommercialTitle, extractBriefSummary } from '../utils/briefParser';
import { parseStyleDescriptors } from '../utils/styleParser';
import { parseTreatmentResponse } from '../utils/treatmentParser';
import { useAuth } from '../contexts/AuthContext';
import { useTemplate } from '../contexts/TemplateContext';
import { useImageUrls } from '../contexts/ImageUrlContext';
import { validateImageUrls } from '../utils/imageHandling';

interface UseStepHandlerProps {
  state: {
    uploadedFileUrl: string;
    uploadedFileType: 1 | 2;
    selectedLanguage: string;
    directorVision: string;
    briefSummary: string;
    styleDescriptors: string[];
    commercialTitle: string;
    slides: { content: string }[];
    setWebhookResponse: (response: string) => void;
    setCommercialTitle: (title: string) => void;
    setBriefSummary: (summary: string) => void;
    setTreatmentResponse: (response: string) => void;
    setSlides: (slides: { content: string }[]) => void;
    setStyleDescriptors: (descriptors: string[]) => void;
    setVisualsResponse: (response: string) => void;
    setFinalResponse: (response: string) => void;
  };
  onError: (error: unknown) => void;
}

export function useStepHandler({ state, onError }: UseStepHandlerProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const { deductTokens, checkTokens } = useTokens();
  const { userProfile } = useAuth();
  const { selectedTemplate } = useTemplate();
  const { getAllImageUrls } = useImageUrls();

  const handleStep1 = async (): Promise<boolean> => {
    if (!state.uploadedFileUrl) {
      throw new Error('No file uploaded');
    }

    if (!checkTokens(TOKEN_COSTS.BRIEF_SUMMARY)) {
      throw new Error('Insufficient tokens');
    }

    setIsProcessing(true);
    try {
      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK1, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fileUrl: state.uploadedFileUrl,
          language: state.selectedLanguage,
          fileType: state.uploadedFileType
        })
      });

      if (!response.ok) {
        throw new Error('Brief summary generation failed');
      }

      const text = await response.text();
      if (!text.trim()) {
        throw new Error('Received empty response from brief summary generation');
      }

      state.setWebhookResponse(text);
      state.setCommercialTitle(extractCommercialTitle(text));
      state.setBriefSummary(extractBriefSummary(text));

      await deductTokens(TOKEN_COSTS.BRIEF_SUMMARY);
      return true;
    } catch (error) {
      onError(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStep2 = async (): Promise<boolean> => {
    if (!state.directorVision.trim()) {
      throw new Error('Director vision is required');
    }

    if (!checkTokens(TOKEN_COSTS.TREATMENT_GENERATION)) {
      throw new Error('Insufficient tokens');
    }

    setIsProcessing(true);
    try {
      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK3, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          briefSummary: state.briefSummary,
          directorVision: state.directorVision,
          language: state.selectedLanguage
        })
      });

      if (!response.ok) {
        throw new Error('Treatment generation failed');
      }

      const text = await response.text();
      if (!text.trim()) {
        throw new Error('Received empty response from treatment generation');
      }

      state.setTreatmentResponse(text);
      state.setSlides(parseTreatmentResponse(text));

      // Generate style descriptors
      const styleResponse = await fetch(import.meta.env.VITE_MAKE_WEBHOOK4, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          briefSummary: state.briefSummary,
          directorVision: state.directorVision,
          language: state.selectedLanguage
        })
      });

      if (!styleResponse.ok) {
        throw new Error('Style descriptors generation failed');
      }

      const styleText = await styleResponse.text();
      state.setStyleDescriptors(parseStyleDescriptors(styleText));

      await deductTokens(TOKEN_COSTS.TREATMENT_GENERATION);
      return true;
    } catch (error) {
      onError(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStep3 = async (): Promise<boolean> => {
    if (!state.slides.length) {
      throw new Error('No slides available');
    }

    if (!checkTokens(TOKEN_COSTS.INITIAL_VISUALS_GENERATION)) {
      throw new Error('Insufficient tokens');
    }

    setIsProcessing(true);
    try {
      const webhookData = {
        language: state.selectedLanguage,
        styleDescriptors: state.styleDescriptors.join(', ')
      };

      state.slides.forEach((slide, index) => {
        const slideNumber = index + 1;
        const titleMatch = slide.content.match(/##(.+?)##/);
        const title = titleMatch ? titleMatch[1].trim() : '';
        const content = slide.content.replace(/##(.+?)##/, '').trim();

        webhookData[`Slide${slideNumber}Title`] = title;

        if (slideNumber === 2) {
          const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
          const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
          
          webhookData[`Slide2TextPart1`] = part1Match ? part1Match[1].trim() : '';
          webhookData[`Slide2TextPart2`] = part2Match ? part2Match[1].trim() : '';
        } else {
          webhookData[`Slide${slideNumber}Text`] = content;
        }
      });

      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK5, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(webhookData)
      });

      if (!response.ok) {
        throw new Error('Visuals generation failed');
      }

      const text = await response.text();
      if (!text.trim()) {
        throw new Error('Received empty response from visuals generation');
      }

      state.setVisualsResponse(text);
      await deductTokens(TOKEN_COSTS.INITIAL_VISUALS_GENERATION);
      return true;
    } catch (error) {
      onError(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleStep4 = async (): Promise<boolean> => {
    if (!selectedTemplate) {
      throw new Error('No template selected');
    }

    const imageUrls = getAllImageUrls();
    if (!validateImageUrls(imageUrls)) {
      throw new Error('Missing image URLs');
    }

    setIsProcessing(true);
    try {
      const webhookData = {
        template: selectedTemplate.filename,
        templateId: selectedTemplate.id.toString(),
        commercialTitle: state.commercialTitle,
        fullName: userProfile?.fullName || '',
      };

      state.slides.forEach((slide, index) => {
        const slideNumber = index + 1;
        const titleMatch = slide.content.match(/##(.+?)##/);
        const title = titleMatch ? titleMatch[1].trim() : '';
        const content = slide.content.replace(/##(.+?)##/, '').trim();

        webhookData[`Slide${slideNumber}Title`] = title;

        if (slideNumber === 2) {
          const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
          const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
          
          webhookData[`Slide2TextPart1`] = part1Match ? part1Match[1].trim() : '';
          webhookData[`Slide2TextPart2`] = part2Match ? part2Match[1].trim() : '';
        } else {
          webhookData[`Slide${slideNumber}Text`] = content;
        }

        for (let i = 0; i < 4; i++) {
          const imageKey = `slide-${slideNumber}-image-${i}`;
          webhookData[`Slide${slideNumber}Image${i + 1}url`] = imageUrls[imageKey] || '';
        }
      });

      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK7, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(webhookData)
      });

      if (!response.ok) {
        throw new Error('Final generation failed');
      }

      const text = await response.text();
      if (!text.trim()) {
        throw new Error('Received empty response from final generation');
      }

      state.setFinalResponse(text);
      return true;
    } catch (error) {
      onError(error);
      return false;
    } finally {
      setIsProcessing(false);
    }
  };

  return {
    isProcessing,
    handleStep1,
    handleStep2,
    handleStep3,
    handleStep4
  };
}