import { useState } from 'react';
import { useTokens } from '../contexts/TokenContext';

export function useTokenCheck() {
  const [showInsufficientTokensModal, setShowInsufficientTokensModal] = useState(false);
  const { tokens } = useTokens();

  const checkTokens = (required: number): boolean => {
    if (tokens < required) {
      setShowInsufficientTokensModal(true);
      return false;
    }
    return true;
  };

  return {
    showInsufficientTokensModal,
    setShowInsufficientTokensModal,
    checkTokens
  };
}