import { useState, useCallback, useEffect, useMemo } from 'react';
import { useImageUrls } from '../contexts/ImageUrlContext';
import { useTokens } from '../contexts/TokenContext';
import { parseVisualsResponse } from '../utils/visualsParser';
import { uploadToUploadcare } from '../utils/uploadcare';
import { TOKEN_COSTS } from '../config/tokenCosts';
import type { SlideData, ImageDimensions } from '../types/visuals';

export function useVisualsManager(visualsResponse: string) {
  const [managedSlides, setManagedSlides] = useState<SlideData[]>([]);
  const [editablePrompts, setEditablePrompts] = useState<{ [key: string]: string }>({});
  const [isGenerating, setIsGenerating] = useState(false);
  const { updateImageUrl } = useImageUrls();
  const { deductTokens } = useTokens();

  const parsedSlides = useMemo(() => {
    if (!visualsResponse) return [];
    return parseVisualsResponse(visualsResponse);
  }, [visualsResponse]);

  useEffect(() => {
    if (parsedSlides.length === 0) return;

    const prompts: { [key: string]: string } = {};
    parsedSlides.forEach(slide => {
      slide.images.forEach((image, index) => {
        prompts[`${slide.slideNumber}-${index}`] = image.prompt;
        updateImageUrl(slide.slideNumber, index, image.link);
      });
    });

    setManagedSlides(parsedSlides);
    setEditablePrompts(prompts);
  }, [parsedSlides]);

  const handleDeleteImage = useCallback((slideNumber: number, imageIndex: number, dimensions?: ImageDimensions) => {
    setManagedSlides(prevSlides => 
      prevSlides.map(slide => {
        if (slide.slideNumber === slideNumber) {
          const newImages = [...slide.images];
          newImages[imageIndex] = {
            ...newImages[imageIndex],
            isDeleted: true,
            dimensions
          };
          return { ...slide, images: newImages };
        }
        return slide;
      })
    );
  }, []);

  const handlePromptChange = useCallback((slideNumber: number, imageIndex: number, newPrompt: string) => {
    setEditablePrompts(prev => ({
      ...prev,
      [`${slideNumber}-${imageIndex}`]: newPrompt
    }));
  }, []);

  const handleFileUpload = useCallback(async (slideNumber: number, imageIndex: number, file: File) => {
    if (!file.type.startsWith('image/')) {
      throw new Error('Please upload an image file');
    }

    try {
      const uploadedUrl = await uploadToUploadcare(file);
      updateImageUrl(slideNumber, imageIndex, uploadedUrl);
      
      // Get uploaded image dimensions
      const img = new Image();
      const dimensions = await new Promise<ImageDimensions>((resolve, reject) => {
        img.onload = () => {
          resolve({
            width: img.naturalWidth,
            height: img.naturalHeight
          });
        };
        img.onerror = () => reject(new Error('Failed to load image'));
        img.src = uploadedUrl;
      });

      setManagedSlides(prevSlides =>
        prevSlides.map(slide => {
          if (slide.slideNumber === slideNumber) {
            const newImages = [...slide.images];
            newImages[imageIndex] = {
              ...newImages[imageIndex],
              link: uploadedUrl,
              isDeleted: false,
              dimensions,
              // Keep original resolution if it exists
              resolution: newImages[imageIndex].resolution
            };
            return { ...slide, images: newImages };
          }
          return slide;
        })
      );
    } catch (error) {
      console.error('Upload error:', error);
      throw error;
    }
  }, [updateImageUrl]);

  const handleGenerateNew = useCallback(async (slideNumber: number, imageIndex: number) => {
    const prompt = editablePrompts[`${slideNumber}-${imageIndex}`];
    if (!prompt?.trim()) {
      throw new Error('Please enter a prompt before generating a new image');
    }

    setIsGenerating(true);
    setManagedSlides(prevSlides =>
      prevSlides.map(slide => {
        if (slide.slideNumber === slideNumber) {
          const newImages = [...slide.images];
          newImages[imageIndex] = {
            ...newImages[imageIndex],
            isGenerating: true
          };
          return { ...slide, images: newImages };
        }
        return slide;
      })
    );

    try {
      // Get the original resolution from the image
      const originalImage = managedSlides
        .find(s => s.slideNumber === slideNumber)
        ?.images[imageIndex];

      const requestBody = {
        prompt,
        resolution: originalImage?.resolution?.suggestedResolution
      };

      const response = await fetch(import.meta.env.VITE_MAKE_WEBHOOK6, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) throw new Error('Generation failed');

      const newImageUrl = await response.text();
      updateImageUrl(slideNumber, imageIndex, newImageUrl);
      
      setManagedSlides(prevSlides =>
        prevSlides.map(slide => {
          if (slide.slideNumber === slideNumber) {
            const newImages = [...slide.images];
            newImages[imageIndex] = {
              prompt,
              link: newImageUrl,
              isDeleted: false,
              isGenerating: false,
              resolution: originalImage?.resolution
            };
            return { ...slide, images: newImages };
          }
          return slide;
        })
      );

      await deductTokens(TOKEN_COSTS.IMAGE_GENERATION);
    } catch (error) {
      setManagedSlides(prevSlides =>
        prevSlides.map(slide => {
          if (slide.slideNumber === slideNumber) {
            const newImages = [...slide.images];
            newImages[imageIndex] = {
              ...newImages[imageIndex],
              isGenerating: false
            };
            return { ...slide, images: newImages };
          }
          return slide;
        })
      );
      throw error;
    } finally {
      setIsGenerating(false);
    }
  }, [editablePrompts, updateImageUrl, deductTokens, managedSlides]);

  return {
    managedSlides,
    editablePrompts,
    isGenerating,
    handleDeleteImage,
    handlePromptChange,
    handleFileUpload,
    handleGenerateNew
  };
}