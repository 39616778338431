import React from 'react';

interface BobSectionProps {
  imageUrl: string;
  message: string;
  reversed?: boolean;
}

export default function BobSection({ imageUrl, message, reversed = false }: BobSectionProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-8">
      <div className={`flex items-center space-x-8 ${reversed ? 'flex-row-reverse space-x-reverse' : ''}`}>
        <img 
          src={imageUrl}
          alt="Virtual Assistant Bob"
          className="w-48 h-48 object-contain flex-shrink-0"
        />
        <p className="text-lg text-gray-700 leading-relaxed">
          {message}
        </p>
      </div>
    </div>
  );
}