export function parseTreatmentResponse(response: string): { content: string }[] {
  if (!response?.trim()) {
    return generateEmptySlides();
  }

  // Remove any text before the first [slide 1] or [Slide 1]
  const cleanedResponse = response.replace(/^[\s\S]*?(?=\[(?:s|S)lide 1\])/g, '');
  if (!cleanedResponse) {
    return generateEmptySlides();
  }

  // Find all slide markers in the text (case insensitive)
  const slideMarkers = [...cleanedResponse.matchAll(/\[(?:s|S)lide \d+\]/g)];
  if (slideMarkers.length === 0) {
    return generateEmptySlides();
  }

  const slides: { content: string }[] = [];
  
  // Process each slide
  for (let i = 0; i < slideMarkers.length; i++) {
    const currentMarker = slideMarkers[i];
    const nextMarker = slideMarkers[i + 1];
    
    // Extract the slide content
    const slideContent = nextMarker 
      ? cleanedResponse.slice(currentMarker.index!, nextMarker.index!)
      : cleanedResponse.slice(currentMarker.index!);
    
    // Parse the slide number
    const slideNumberMatch = slideContent.match(/\[(?:s|S)lide (\d+)\]/);
    if (!slideNumberMatch) continue;
    
    const slideNumber = parseInt(slideNumberMatch[1], 10);
    if (isNaN(slideNumber) || slideNumber < 1 || slideNumber > 8) continue;
    
    // Remove the slide marker and process the content
    const contentWithoutMarker = slideContent.replace(/\[(?:s|S)lide \d+\]/, '').trim();
    
    // Extract title and content
    const titleMatch = contentWithoutMarker.match(/##(.*?)##/);
    const title = titleMatch ? titleMatch[1].trim() : 'Untitled';
    let content = contentWithoutMarker.replace(/##.*?##/, '').trim();
    
    // Special handling for slide 2 with parts
    if (slideNumber === 2) {
      const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
      const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
      
      const part1 = part1Match ? part1Match[1].trim() : '';
      const part2 = part2Match ? part2Match[1].trim() : '';
      
      content = `##${title}##\n\n[part1]\n${part1}\n\n[part2]\n${part2}`;
    } else {
      content = `##${title}##\n\n${content}`;
    }
    
    // Store the slide in the correct position
    slides[slideNumber - 1] = { content };
  }
  
  // Fill any gaps and ensure exactly 8 slides
  return normalizeSlides(slides);
}

function generateEmptySlides(): { content: string }[] {
  return Array(8).fill(null).map((_, index) => ({
    content: `##Slide ${index + 1}##\n\nNo content yet`
  }));
}

function normalizeSlides(slides: ({ content: string } | undefined)[]): { content: string }[] {
  const normalized = Array(8).fill(null).map((_, index) => {
    if (slides[index]?.content) {
      return slides[index];
    }
    return {
      content: `##Slide ${index + 1}##\n\nNo content yet`
    };
  });
  
  return normalized;
}