import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Check, Loader2 } from 'lucide-react';
import { handleSubscription } from '../utils/subscription';
import type { PlanType } from '../utils/subscription';

const PLANS = [
  {
    name: 'Free',
    price: '$0',
    tokens: '500',
    features: [
      'Basic treatment generation',
      'Limited image generation',
      'Standard support'
    ]
  },
  {
    name: 'Basic Plan',
    price: '$25',
    tokens: '2,500',
    features: [
      'Advanced treatment generation',
      'Enhanced image generation', 
      'Priority support',
      'Custom templates'
    ]
  },
  {
    name: 'Pro Plan',
    price: '$49',
    tokens: '6,000',
    features: [
      'Premium treatment generation',
      'Unlimited image generation',
      '24/7 Priority support',
      'Custom templates',
      'API access'
    ]
  }
];

export default function ManageSubscription() {
  const navigate = useNavigate();
  const { user, userProfile } = useAuth();
  const [isLoading, setIsLoading] = useState<string | null>(null);

  const handleSubscribe = async (planName: string) => {
    if (!user) {
      navigate('/login');
      return;
    }

    // If selecting free plan, just navigate to dashboard
    if (planName === 'Free') {
      navigate('/dashboard');
      return;
    }

    try {
      setIsLoading(planName);
      const plan = planName.toLowerCase().replace(' plan', '') as PlanType;
      const paymentLink = await handleSubscription(userProfile?.stripeCustomerId || null, plan);
      window.location.href = paymentLink;
    } catch (err) {
      console.error('Subscription error:', err);
      alert('Failed to start subscription process. Please try again.');
    } finally {
      setIsLoading(null);
    }
  };

  const getCurrentPlanName = () => {
    if (!userProfile?.currentPlan) return '';
    return userProfile.currentPlan === 'basic' ? 'Basic Plan' :
           userProfile.currentPlan === 'pro' ? 'Pro Plan' : 'Free';
  };

  return (
    <div className="py-12 bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Manage Your Subscription
          </h2>
          <p className="mt-4 text-xl text-gray-600">
            Current Plan: {getCurrentPlanName()}
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {PLANS.map((plan) => (
            <div
              key={plan.name}
              className={`rounded-lg shadow-lg overflow-hidden ${
                getCurrentPlanName() === plan.name 
                  ? 'ring-2 ring-primary-500 transform scale-105'
                  : ''
              }`}
            >
              <div className="px-6 py-8 bg-white">
                <div className="flex justify-between items-center">
                  <h3 className="text-2xl font-semibold text-gray-900">
                    {plan.name}
                  </h3>
                  {getCurrentPlanName() === plan.name && (
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-primary-100 text-primary-800">
                      <Check className="w-4 h-4 mr-1" />
                      Current
                    </span>
                  )}
                </div>
                <p className="mt-4">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {plan.price}
                  </span>
                  <span className="text-base font-medium text-gray-500">
                    /month
                  </span>
                </p>
                <p className="mt-2 text-sm text-gray-500">
                  {plan.tokens} tokens per month
                </p>

                <ul className="mt-6 space-y-4">
                  {plan.features.map((feature) => (
                    <li key={feature} className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className="h-5 w-5 text-green-500" />
                      </div>
                      <p className="ml-3 text-sm text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>

                <button
                  onClick={() => handleSubscribe(plan.name)}
                  disabled={isLoading !== null}
                  className={`mt-8 w-full px-4 py-2 border border-transparent text-sm font-medium rounded-md ${
                    isLoading !== null
                      ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                      : 'bg-primary-600 text-white hover:bg-primary-700'
                  }`}
                >
                  {isLoading === plan.name ? (
                    <div className="flex items-center justify-center">
                      <Loader2 className="w-4 h-4 mr-2 animate-spin" />
                      Processing...
                    </div>
                  ) : 'Subscribe'}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
