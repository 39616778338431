import React from 'react';
import ImageCard from './ImageCard';
import type { ImageDimensions } from '../types/visuals';

interface SlideVisualsProps {
  slideNumber: number;
  images: Array<{
    prompt: string;
    link: string;
    isDeleted?: boolean;
    isGenerating?: boolean;
    dimensions?: ImageDimensions;
  }>;
  editablePrompts: { [key: string]: string };
  onDeleteImage: (imageIndex: number, dimensions?: ImageDimensions) => void;
  onPromptChange: (imageIndex: number, newPrompt: string) => void;
  onFileUpload: (imageIndex: number, file: File) => void;
  onGenerateNew: (imageIndex: number, dimensions?: ImageDimensions) => void;
}

export default function SlideVisuals({
  slideNumber,
  images,
  editablePrompts,
  onDeleteImage,
  onPromptChange,
  onFileUpload,
  onGenerateNew
}: SlideVisualsProps) {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">
        Slide {slideNumber}
      </h3>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {images.map((image, index) => (
          <ImageCard
            key={index}
            slideNumber={slideNumber}
            imageIndex={index}
            image={image}
            editablePrompt={editablePrompts[`${slideNumber}-${index}`] || ''}
            onDelete={(dimensions) => onDeleteImage(index, dimensions)}
            onPromptChange={(newPrompt) => onPromptChange(index, newPrompt)}
            onFileUpload={(file) => onFileUpload(index, file)}
            onGenerateNew={(dimensions) => onGenerateNew(index, dimensions)}
          />
        ))}
      </div>
    </div>
  );
}