// Token costs for different operations
export const TOKEN_COSTS = {
  BRIEF_SUMMARY: 20,
  VISION_GENERATION: 15,
  TREATMENT_GENERATION: 30,
  IMAGE_GENERATION: 35,
  INITIAL_VISUALS_GENERATION: 400, // 32 images
  TEXT_REGENERATION: 20 // Cost for regenerating text in step 3
} as const;

// Helper function to get cost of an operation
export const getOperationCost = (operation: keyof typeof TOKEN_COSTS): number => {
  return TOKEN_COSTS[operation];
};