import { ImageData, SlideData } from '../types/visuals';

export function parseVisualsResponse(visualsResponse: string): SlideData[] {
  if (!visualsResponse) return [];

  const slides: SlideData[] = [];
  const slideRegex = /\[Slide (\d+)\]([\s\S]*?)(?=\[Slide \d+\]|$)/g;
  const imageRegex = /\[prompt(\d+)\]\s*(.*?)\s*\[url\1\]\s*(https?:\/\/[^\s]+)\s*\[res\1\]\s*([^\n]+)/g;

  try {
    let slideMatch;
    while ((slideMatch = slideRegex.exec(visualsResponse)) !== null) {
      const slideNumber = parseInt(slideMatch[1]);
      const slideContent = slideMatch[2].trim();
      const images: ImageData[] = [];

      if (isNaN(slideNumber)) continue;

      let imageMatch;
      imageRegex.lastIndex = 0;
      
      while ((imageMatch = imageRegex.exec(slideContent)) !== null) {
        const [_, index, promptText, url, resolution] = imageMatch;
        
        if (promptText && url && resolution) {
          images.push({
            prompt: promptText.trim(),
            link: url.trim(),
            isDeleted: false,
            isGenerating: false,
            resolution: {
              width: 0,
              height: 0,
              suggestedResolution: resolution.trim()
            }
          });
        }
      }

      // Ensure we always have exactly 4 image slots per slide
      while (images.length < 4) {
        images.push({
          prompt: '',
          link: '',
          isDeleted: true,
          isGenerating: false
        });
      }

      slides.push({ 
        slideNumber, 
        images: images.slice(0, 4)
      });
    }

    // Sort slides by number and ensure we have exactly 8 slides
    const sortedSlides = slides
      .sort((a, b) => a.slideNumber - b.slideNumber)
      .slice(0, 8);

    // Add any missing slides up to 8
    while (sortedSlides.length < 8) {
      const slideNumber = sortedSlides.length + 1;
      sortedSlides.push({
        slideNumber,
        images: Array(4).fill({
          prompt: '',
          link: '',
          isDeleted: true,
          isGenerating: false
        })
      });
    }

    return sortedSlides;
  } catch (error) {
    console.error('Error parsing visuals response:', error);
    return Array.from({ length: 8 }, (_, i) => ({
      slideNumber: i + 1,
      images: Array(4).fill({
        prompt: '',
        link: '',
        isDeleted: true,
        isGenerating: false
      })
    }));
  }
}