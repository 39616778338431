import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useTokens } from '../contexts/TokenContext';
import { useImageUrls } from '../contexts/ImageUrlContext';
import { useTemplate } from '../contexts/TemplateContext';
import { useTokenCheck } from '../hooks/useTokenCheck';
import { useWizardState } from '../hooks/useWizardState';
import { useWizardNavigation } from '../hooks/useWizardNavigation';
import { useStepHandler } from '../hooks/useStepHandler';
import { useErrorHandling } from '../hooks/useErrorHandling';
import { useVisionGeneration } from '../hooks/useVisionGeneration';
import { useUnsavedChanges } from '../hooks/useUnsavedChanges';
import TopBar from '../components/TopBar';
import BobSection from '../components/BobSection';
import BriefUpload from '../components/BriefUpload';
import VoiceInput from '../components/VoiceInput';
import TreatmentSlide from '../components/TreatmentSlide';
import StyleDescriptors from '../components/StyleDescriptors';
import VisualsDisplay from '../components/VisualsDisplay';
import CommercialTitle from '../components/CommercialTitle';
import LoadingMessage from '../components/LoadingMessage';
import InsufficientTokensModal from '../components/InsufficientTokensModal';
import DownloadButtons from '../components/DownloadButtons';
import FinishButton from '../components/FinishButton';
import LoadingOverlay from '../components/LoadingOverlay';
import NavigationConfirmModal from '../components/NavigationConfirmModal';
import { languages } from '../constants/languages';
import { TOKEN_COSTS } from '../config/tokenCosts';
import { validateImageUrls } from '../utils/imageHandling';
import { extractFileUrls } from '../utils/downloadUtils';

export default function Wizard() {
  const navigate = useNavigate();
  const wizardState = useWizardState();
  const { currentStep, handlePreviousStep, goToStep } = useWizardNavigation();
  const { error, handleError, clearError } = useErrorHandling();
  const { showInsufficientTokensModal, setShowInsufficientTokensModal, checkTokens } = useTokenCheck();
  const { userProfile } = useAuth();
  const { getAllImageUrls } = useImageUrls();
  const { selectedTemplate } = useTemplate();
  const { deductTokens } = useTokens();
  const {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    showConfirmModal,
    handleUnsavedChanges,
    handleConfirm,
    handleCancel
  } = useUnsavedChanges();

  const { isGenerating: isVisionGenerating, generateVision } = useVisionGeneration({
    briefSummary: wizardState.briefSummary,
    selectedLanguage: wizardState.selectedLanguage,
    onVisionGenerated: wizardState.setDirectorVision
  });

  const stepHandler = useStepHandler({
    state: wizardState,
    onError: handleError
  });

  const handleNextStep = () => {
    handleUnsavedChanges(async () => {
      try {
        wizardState.setIsLoading(true);
        clearError();

        const requiredTokens = currentStep === 1 ? TOKEN_COSTS.BRIEF_SUMMARY :
                             currentStep === 2 ? TOKEN_COSTS.TREATMENT_GENERATION :
                             currentStep === 3 ? TOKEN_COSTS.INITIAL_VISUALS_GENERATION : 0;

        if (requiredTokens > 0 && !checkTokens(requiredTokens)) {
          setShowInsufficientTokensModal(true);
          return;
        }

        let success = false;

        if (currentStep === 1) {
          if (!wizardState.uploadedFileUrl) {
            handleError(new Error('Please upload a file first'));
            return;
          }
          success = await stepHandler.handleStep1();
        } else if (currentStep === 2) {
          success = await stepHandler.handleStep2();
        } else if (currentStep === 3) {
          success = await stepHandler.handleStep3();
        } else if (currentStep === 4) {
          const imageUrls = getAllImageUrls();
          if (!validateImageUrls(imageUrls)) {
            throw new Error('Missing image URLs');
          }
          success = await stepHandler.handleStep4();
        }

        if (success) {
          goToStep(currentStep + 1);
        }
      } catch (err) {
        handleError(err);
      } finally {
        wizardState.setIsLoading(false);
      }
    });
  };

  const handleVisionGenerate = async () => {
    if (!wizardState.briefSummary.trim()) {
      handleError(new Error('Brief summary is required for vision generation'));
      return;
    }

    if (!checkTokens(TOKEN_COSTS.VISION_GENERATION)) {
      setShowInsufficientTokensModal(true);
      return;
    }

    try {
      await generateVision();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <TopBar />
      
      <div className="pt-20 pb-8">
        <div className="max-w-4xl mx-auto px-4">
          <div className="flex justify-between items-center">
            {[1, 2, 3, 4, 5].map((step) => (
              <div
                key={step}
                className="flex items-center cursor-pointer"
                onClick={() => step < currentStep && goToStep(step)}
              >
                <div
                  className={`w-10 h-10 rounded-full flex items-center justify-center ${
                    step === currentStep
                      ? 'bg-primary-500 text-white'
                      : step < currentStep
                      ? 'bg-secondary-500 text-white'
                      : 'bg-gray-200 text-gray-500'
                  }`}
                >
                  {step}
                </div>
                <div className="ml-2">
                  <div className="text-sm font-medium text-gray-900">
                    {step === 1 && 'Brief'}
                    {step === 2 && 'Directions'}
                    {step === 3 && 'Treatment'}
                    {step === 4 && 'Visuals'}
                    {step === 5 && 'Export'}
                  </div>
                  <div className="text-xs text-gray-500">
                    {step === 1 && 'Project details'}
                    {step === 2 && 'Creative direction'}
                    {step === 3 && 'Edit treatment'}
                    {step === 4 && 'Add visuals'}
                    {step === 5 && 'Final export'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {currentStep === 1 && (
        <div className="max-w-4xl mx-auto space-y-8 p-8 pb-32">
          <BobSection
            imageUrl="https://ucarecdn.com/d3d1c15d-d24e-4d43-ab0e-ad15768257d6/Bob1.jpeg"
            message="Hi, I'm your new assistant. My name is Bob, but other directors usually call me 'Hey You!' or 'Coffee Guy.' I will assist you today in writing your treatment so you can focus on other tasks. First, please upload the agency brief and choose which language you'd like to work in. I will read it and summarise it to you."
          />

          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Select Language</h3>
            <select
              value={wizardState.selectedLanguage}
              onChange={(e) => wizardState.setSelectedLanguage(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md bg-white shadow-sm focus:ring-primary-500 focus:border-primary-500"
            >
              {languages.map((lang) => (
                <option key={lang.code} value={lang.name}>
                  {lang.name}
                </option>
              ))}
            </select>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Upload Agency Brief</h3>
            <BriefUpload onUploadComplete={(fileUrl, isPdf) => {
              wizardState.setUploadedFileUrl(fileUrl);
              wizardState.setUploadedFileType(isPdf ? 1 : 2);
            }} />
          </div>
        </div>
      )}

      {currentStep === 2 && (
        <div className="max-w-4xl mx-auto space-y-6 p-8 pb-32">
          <BobSection
            imageUrl="https://ucarecdn.com/0e1ab5a0-ab68-4d91-b2c4-4e09a5cee4d5/Bob2.jpeg"
            message="It's Bob again. Remember me? I've read the agency brief, and I think I'll need some input from you to write an effective treatment. Could you briefly share your vision for this commercial? If you're busy, I can draft something to get us started. I've summarized the agency brief below for your convenience."
            reversed
          />

          <CommercialTitle
            initialTitle={wizardState.commercialTitle}
            onSave={wizardState.setCommercialTitle}
          />

          <div className="bg-white rounded-lg shadow-lg p-8">
            <h3 className="text-xl font-semibold text-gray-900 mb-4">Your Vision</h3>
            <div className="space-y-4">
              <textarea
                value={wizardState.directorVision}
                onChange={(e) => wizardState.setDirectorVision(e.target.value)}
                placeholder="Share your creative vision for this commercial, or use the microphone to record your thoughts. If you need inspiration, click 'Generate' and I'll help you get started."
                className="w-full h-48 p-4 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 resize-none"
              />
              <div className="flex justify-between items-center">
                <button
                  onClick={handleVisionGenerate}
                  disabled={isVisionGenerating}
                  className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
                >
                  {isVisionGenerating ? (
                    <div className="flex items-center space-x-2">
                      <Loader2 className="h-5 w-5 animate-spin" />
                      <span>Generating...</span>
                    </div>
                  ) : (
                    'Generate Vision'
                  )}
                </button>
                <VoiceInput onTranscriptionComplete={wizardState.setDirectorVision} />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg">
            <details className="group" open>
              <summary className="flex items-center justify-between p-8 cursor-pointer list-none">
                <h3 className="text-xl font-semibold text-gray-900">Agency Brief Summary</h3>
                <div className="flex items-center space-x-2">
                  <span className="text-sm text-gray-500 group-open:hidden">Show</span>
                  <span className="text-sm text-gray-500 hidden group-open:block">Hide</span>
                  <svg 
                    className="w-5 h-5 text-gray-500 transform transition-transform duration-200 group-open:rotate-180" 
                    fill="none" 
                    viewBox="0 0 24 24" 
                    stroke="currentColor"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                  </svg>
                </div>
              </summary>
              <div className="px-8 pb-8 prose max-w-none text-gray-700 whitespace-pre-wrap border-t border-gray-100">
                {wizardState.briefSummary.split('**').map((text, index) => (
                  index % 2 === 1 ? <strong key={index}>{text}</strong> : text
                ))}
              </div>
            </details>
          </div>
        </div>
      )}

      {currentStep === 3 && (
        <div className="max-w-4xl mx-auto space-y-6 p-8 pb-32">
          <BobSection
            imageUrl="https://ucarecdn.com/0e1ab5a0-ab68-4d91-b2c4-4e09a5cee4d5/Bob3.jpeg"
            message="Hey there!  I've whipped up a draft of the treatment for you. Take a peek and feel free to put your personal touch on any slide – make it shine!
Pro tip: Pay special attention to those visual descriptors. They're like the secret sauce for generating our images, so if you've got any cool ideas brewing in that creative mind of yours, now's the perfect time to sprinkle them in!
The next step is coming up, and it might take a quick coffee break (around 3 minutes)."
            reversed
          />
          <StyleDescriptors
            descriptors={wizardState.styleDescriptors}
            onChange={wizardState.setStyleDescriptors}
          />
          {wizardState.slides.map((slide, index) => (
            <TreatmentSlide
              key={index}
              slideNumber={index + 1}
              content={slide.content}
              onSave={(content) => {
                const newSlides = [...wizardState.slides];
                newSlides[index] = { content };
                wizardState.setSlides(newSlides);
              }}
              onUnsavedChange={setHasUnsavedChanges}
              selectedLanguage={wizardState.selectedLanguage}
              briefSummary={wizardState.briefSummary}
              directorVision={wizardState.directorVision}
            />
          ))}
        </div>
      )}

      {currentStep === 4 && (
        <div className="max-w-4xl mx-auto space-y-6 p-8 pb-32">
          <BobSection
            imageUrl="https://ucarecdn.com/0e1ab5a0-ab68-4d91-b2c4-4e09a5cee4d5/Bob4.jpeg"
            message="Hey there!  I've whipped up a draft of the treatment for you. Take a peek and feel free to put your personal touch on any slide – make it shine!
Pro tip: Pay special attention to those visual descriptors. They're like the secret sauce for generating our images, so if you've got any cool ideas brewing in that creative mind of yours, now's the perfect time to sprinkle them in!
The next step is coming up, and it might take a quick coffee break (around 3 minutes)."
            reversed
          />
          <VisualsDisplay visualsResponse={wizardState.visualsResponse} />
        </div>
      )}

      {currentStep === 5 && (
        <div className="max-w-4xl mx-auto space-y-6 p-8 pb-32">
          <BobSection
            imageUrl="https://ucarecdn.com/0e1ab5a0-ab68-4d91-b2c4-4e09a5cee4d5/Bob5.jpeg"
            message="Woohoo! 🎉 Your treatment is all set and looking fabulous!
Can I just say – working with you has been awesome? I've learned so much, and I'm definitely stealing this collaboration for my professional highlight reel! 
You've got tons of ways to work with this masterpiece. You can download it as a sleek PDF or grab the PowerPoint version. If you're feeling creative, jump into Google Slides, PowerPoint, or Pages and keep polishing your work.
When you're happy with the final result, just hit that 'Finish' button to save it to your personal treatments collection. Everything will be neatly tucked away in your dashboard, ready for your next brilliant moment."
            reversed
          />
          <DownloadButtons
            pdfUrl={extractFileUrls(wizardState.finalResponse).pdfUrl}
            pptxUrl={extractFileUrls(wizardState.finalResponse).pptxUrl}
            title={wizardState.commercialTitle}
          />
        </div>
      )}

      <div className="fixed bottom-0 left-0 right-0 bg-white/80 backdrop-blur-sm shadow-lg">
        <div className="max-w-4xl mx-auto px-4 py-4 flex justify-between items-center">
          {currentStep > 1 ? (
            <button
              onClick={handlePreviousStep}
              className="px-6 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 w-32 text-center"
            >
              Previous
            </button>
          ) : (
            <div className="w-32" />
          )}
          
          <LoadingMessage isLoading={wizardState.isLoading} />
          
          {currentStep < 5 ? (
            <button
              onClick={handleNextStep}
              disabled={wizardState.isLoading}
              className={`px-6 py-2 rounded-md text-white font-medium w-32 text-center
                ${wizardState.isLoading
                  ? 'bg-primary-300 cursor-not-allowed'
                  : 'bg-primary-600 hover:bg-primary-700'
                }
                flex items-center justify-center space-x-2`}
            >
              {wizardState.isLoading ? (
                <>
                  <Loader2 className="h-5 w-5 animate-spin" />
                  <span>Processing</span>
                </>
              ) : (
                'Next step'
              )}
            </button>
          ) : (
            <FinishButton
              finalResponse={wizardState.finalResponse}
              title={wizardState.commercialTitle}
              isLoading={wizardState.isLoading}
            />
          )}
        </div>
      </div>

      <InsufficientTokensModal
        isOpen={showInsufficientTokensModal}
        onClose={() => setShowInsufficientTokensModal(false)}
      />

      <NavigationConfirmModal
        isOpen={showConfirmModal}
        onClose={handleCancel}
        onConfirm={handleConfirm}
        title="Unsaved Changes"
        message="You have unsaved changes. Are you sure you want to continue without saving?"
      />

      <LoadingOverlay 
        isVisible={wizardState.isLoading} 
        message="Processing your request..."
      />
    </div>
  );
}