import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Video, Coins, LogOut, Loader, AlertCircle, User } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { useTokens } from '../contexts/TokenContext';
import NavigationConfirmModal from './NavigationConfirmModal';
import UserDashboardModal from './UserDashboardModal';

export default function TopBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();
  const { tokens, loading, error } = useTokens();
  const [showConfirmModal, setShowConfirmModal] = useState<{
    isOpen: boolean;
    action: 'home' | 'logout';
  }>({ isOpen: false, action: 'home' });
  const [showUserDashboard, setShowUserDashboard] = useState(false);

  const isWizardPage = location.pathname === '/create-treatment';

  const handleLogoClick = () => {
    if (isWizardPage) {
      setShowConfirmModal({ isOpen: true, action: 'home' });
    } else {
      navigate('/dashboard');
    }
  };

  const handleLogoutClick = () => {
    if (isWizardPage) {
      setShowConfirmModal({ isOpen: true, action: 'logout' });
    } else {
      handleLogout();
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const handleConfirm = () => {
    if (showConfirmModal.action === 'home') {
      navigate('/dashboard');
    } else {
      handleLogout();
    }
    setShowConfirmModal({ isOpen: false, action: 'home' });
  };

  return (
    <>
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div 
                className="flex items-center space-x-2 cursor-pointer" 
                onClick={handleLogoClick}
              >
                <Video className="h-8 w-8 text-primary-500" />
                <span className="text-2xl font-bold text-gray-900">RealTreat</span>
              </div>
            </div>
            <div className="flex items-center space-x-6">
              <div className="flex items-center bg-secondary-50 rounded-full px-4 py-1">
                <Coins className="h-4 w-4 text-secondary-500 mr-2" />
                {loading ? (
                  <Loader className="h-4 w-4 animate-spin text-secondary-500" />
                ) : (
                  <span className="text-sm font-medium text-secondary-700">
                    {tokens} tokens
                  </span>
                )}
              </div>
              {error && (
                <div className="flex items-center text-red-600">
                  <AlertCircle className="h-4 w-4 mr-1" />
                  <span className="text-sm">{error}</span>
                </div>
              )}
              <button
                onClick={() => setShowUserDashboard(true)}
                className="flex items-center space-x-2 px-3 py-2 text-gray-700 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors"
              >
                <User className="h-4 w-4" />
                <span className="text-sm">{user?.email}</span>
              </button>
              <button
                onClick={handleLogoutClick}
                className="flex items-center space-x-2 px-3 py-2 text-gray-700 hover:text-gray-900 rounded-md hover:bg-gray-100 transition-colors"
              >
                <LogOut className="h-4 w-4" />
                <span className="text-sm">Logout</span>
              </button>
            </div>
          </div>
        </div>
      </nav>

      <NavigationConfirmModal
        isOpen={showConfirmModal.isOpen}
        onClose={() => setShowConfirmModal({ isOpen: false, action: 'home' })}
        onConfirm={handleConfirm}
        title={showConfirmModal.action === 'home' ? 'Leave Page?' : 'Logout?'}
      />

      <UserDashboardModal
        isOpen={showUserDashboard}
        onClose={() => setShowUserDashboard(false)}
      />
    </>
  );
}