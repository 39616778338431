import { WebhookData } from '../types/webhook';
import { ImageDimensions } from '../types/visuals';

export function validateImageUrls(imageUrls: { [key: string]: string }): boolean {
  if (!imageUrls) return false;

  for (let slideNumber = 1; slideNumber <= 8; slideNumber++) {
    for (let imageNumber = 0; imageNumber < 4; imageNumber++) {
      const key = `slide-${slideNumber}-image-${imageNumber}`;
      const url = imageUrls[key];
      
      // Check if URL exists and is valid
      if (!url || !url.startsWith('http')) {
        console.warn(`Invalid or missing image URL for ${key}`);
        return false;
      }
    }
  }
  return true;
}

export function prepareWebhookData(
  slides: { content: string }[],
  imageUrls: { [key: string]: string },
  templateId: string,
  presentationTitle: string,
  fullName: string
): WebhookData {
  const webhookData: WebhookData = {
    template: templateId,
    templateId,
    presentationTitle,
    commercialTitle: presentationTitle,
    fullName
  };

  slides.forEach((slide, index) => {
    const slideNumber = index + 1;
    const titleMatch = slide.content.match(/##(.+?)##/);
    const title = titleMatch ? titleMatch[1].trim() : '';
    const content = slide.content.replace(/##(.+?)##/, '').trim();
    
    webhookData[`Slide${slideNumber}Title`] = title;

    if (slideNumber === 2) {
      const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
      const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
      
      webhookData[`Slide2TextPart1`] = part1Match ? part1Match[1].trim() : '';
      webhookData[`Slide2TextPart2`] = part2Match ? part2Match[1].trim() : '';
    } else {
      webhookData[`Slide${slideNumber}Text`] = content;
    }

    for (let i = 0; i < 4; i++) {
      const imageKey = `slide-${slideNumber}-image-${i}`;
      webhookData[`Slide${slideNumber}Image${i + 1}url`] = imageUrls[imageKey] || '';
    }
  });

  return webhookData;
}

export function prepareSlideData(
  slides: { content: string }[],
  styleDescriptors: string[]
): WebhookData {
  const slideData: WebhookData = {
    styleDescriptors: styleDescriptors.join(', ')
  };

  slides.forEach((slide, index) => {
    const slideNumber = index + 1;
    const titleMatch = slide.content.match(/##(.+?)##/);
    const title = titleMatch ? titleMatch[1].trim() : '';
    const content = slide.content.replace(/##(.+?)##/, '').trim();
    
    slideData[`Slide${slideNumber}Title`] = title;

    if (slideNumber === 2) {
      const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
      const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
      
      slideData[`Slide2TextPart1`] = part1Match ? part1Match[1].trim() : '';
      slideData[`Slide2TextPart2`] = part2Match ? part2Match[1].trim() : '';
    } else {
      slideData[`Slide${slideNumber}Text`] = content;
    }
  });

  return slideData;
}