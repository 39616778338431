import React, { useState, useCallback, useEffect } from 'react';
import { Edit2, Save, X, Wand2, Loader2 } from 'lucide-react';
import { useTokens } from '../contexts/TokenContext';
import { TOKEN_COSTS } from '../config/tokenCosts';

interface TreatmentSlideProps {
  slideNumber: number;
  content: string;
  onSave: (content: string) => void;
  onUnsavedChange?: (hasChanges: boolean) => void;
  selectedLanguage?: string;
  briefSummary?: string;
  directorVision?: string;
}

export default function TreatmentSlide({
  slideNumber,
  content,
  onSave,
  onUnsavedChange,
  selectedLanguage,
  briefSummary,
  directorVision
}: TreatmentSlideProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [isRegeneratingPart1, setIsRegeneratingPart1] = useState(false);
  const [isRegeneratingPart2, setIsRegeneratingPart2] = useState(false);
  const [isRegeneratingMain, setIsRegeneratingMain] = useState(false);
  const [regeneratePromptPart1, setRegeneratePromptPart1] = useState('');
  const [regeneratePromptPart2, setRegeneratePromptPart2] = useState('');
  const [regeneratePromptMain, setRegeneratePromptMain] = useState('');
  const { deductTokens, checkTokens } = useTokens();
  
  // Extract title and content parts
  const titleMatch = content.match(/##(.+?)##/);
  const initialTitle = titleMatch ? titleMatch[1].trim() : '';
  
  // For Slide 2, extract part1 and part2
  let initialPart1 = '';
  let initialPart2 = '';
  let initialContent = '';
  
  if (slideNumber === 2) {
    const part1Match = content.match(/\[part1\]([\s\S]*?)\[part2\]/);
    const part2Match = content.match(/\[part2\]([\s\S]*?)$/);
    
    initialPart1 = part1Match ? part1Match[1].trim() : '';
    initialPart2 = part2Match ? part2Match[1].trim() : '';
  } else {
    initialContent = content.replace(/##(.+?)##/, '').trim();
  }
  
  const [editedTitle, setEditedTitle] = useState(initialTitle);
  const [editedContent, setEditedContent] = useState(initialContent);
  const [editedPart1, setEditedPart1] = useState(initialPart1);
  const [editedPart2, setEditedPart2] = useState(initialPart2);

  useEffect(() => {
    setEditedTitle(initialTitle);
    if (slideNumber === 2) {
      setEditedPart1(initialPart1);
      setEditedPart2(initialPart2);
    } else {
      setEditedContent(initialContent);
    }
  }, [content, slideNumber, initialTitle, initialContent, initialPart1, initialPart2]);

  const handleRegenerate = async (part?: 'part1' | 'part2') => {
    if (!checkTokens(TOKEN_COSTS.TEXT_REGENERATION)) {
      alert('Insufficient tokens');
      return;
    }

    // Set the appropriate loading state
    if (part === 'part1') setIsRegeneratingPart1(true);
    else if (part === 'part2') setIsRegeneratingPart2(true);
    else setIsRegeneratingMain(true);

    try {
      const prompt = part === 'part1' ? regeneratePromptPart1 :
                     part === 'part2' ? regeneratePromptPart2 :
                     regeneratePromptMain;

      const response = await fetch('https://hook.eu2.make.com/2545hrlnf5lb1abextpv08ebfc19mf69', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          slideNr: part ? `slide2${part}` : `slide${slideNumber}`,
          prompt,
          briefSummary,
          directorVision,
          language: selectedLanguage
        })
      });

      if (!response.ok) {
        throw new Error('Failed to regenerate text');
      }

      const newText = await response.text();
      
      if (part === 'part1') {
        setEditedPart1(newText);
        setRegeneratePromptPart1('');
      } else if (part === 'part2') {
        setEditedPart2(newText);
        setRegeneratePromptPart2('');
      } else {
        setEditedContent(newText);
        setRegeneratePromptMain('');
      }

      await deductTokens(TOKEN_COSTS.TEXT_REGENERATION);
      onUnsavedChange?.(true);
    } catch (error) {
      console.error('Error regenerating text:', error);
      alert('Failed to regenerate text. Please try again.');
    } finally {
      if (part === 'part1') setIsRegeneratingPart1(false);
      else if (part === 'part2') setIsRegeneratingPart2(false);
      else setIsRegeneratingMain(false);
    }
  };

  const renderRegenerateSection = (part?: 'part1' | 'part2') => {
    const isRegenerating = part === 'part1' ? isRegeneratingPart1 :
                          part === 'part2' ? isRegeneratingPart2 :
                          isRegeneratingMain;
    
    const prompt = part === 'part1' ? regeneratePromptPart1 :
                  part === 'part2' ? regeneratePromptPart2 :
                  regeneratePromptMain;
    
    const setPrompt = part === 'part1' ? setRegeneratePromptPart1 :
                     part === 'part2' ? setRegeneratePromptPart2 :
                     setRegeneratePromptMain;

    return (
      <div className="mt-4 space-y-2">
        <input
          type="text"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
          placeholder="Let me know here what you want to change."
        />
        <button
          onClick={() => handleRegenerate(part)}
          disabled={isRegenerating}
          className={`flex items-center space-x-2 px-4 py-2 rounded-md text-white
            ${isRegenerating
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-primary-600 hover:bg-primary-700'}`}
        >
          {isRegenerating ? (
            <>
              <Loader2 className="h-4 w-4 animate-spin" />
              <span>Regenerating...</span>
            </>
          ) : (
            <>
              <Wand2 className="h-4 w-4" />
              <span>Regenerate {part ? `Part ${part === 'part1' ? '1' : '2'}` : 'Content'}</span>
            </>
          )}
        </button>
      </div>
    );
  };

  const handleSave = () => {
    let newContent;
    if (slideNumber === 2) {
      newContent = `##${editedTitle}##\n\n[part1]\n${editedPart1}\n\n[part2]\n${editedPart2}`;
    } else {
      newContent = `##${editedTitle}##\n\n${editedContent}`;
    }
    onSave(newContent);
    setIsEditing(false);
    onUnsavedChange?.(false);
  };

  const handleCancel = () => {
    setEditedTitle(initialTitle);
    if (slideNumber === 2) {
      setEditedPart1(initialPart1);
      setEditedPart2(initialPart2);
    } else {
      setEditedContent(initialContent);
    }
    setIsEditing(false);
    onUnsavedChange?.(false);
  };

  const renderContent = (text: string) => {
    if (!text) return null;
    
    return text.split('\n').map((paragraph, index) => (
      <p key={index} className={`${index > 0 ? 'mt-4' : ''} text-gray-700`}>
        {paragraph.split('**').map((part, i) => (
          i % 2 === 1 ? <strong key={i}>{part}</strong> : part
        ))}
      </p>
    ));
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex justify-between items-center mb-4">
        <h4 className="text-lg font-semibold text-gray-900">Slide {slideNumber}</h4>
        {isEditing ? (
          <div className="flex space-x-2">
            <button
              onClick={handleSave}
              className="flex items-center space-x-1 px-3 py-1 bg-green-600 text-white rounded-md hover:bg-green-700"
            >
              <Save className="h-4 w-4" />
              <span>Save</span>
            </button>
            <button
              onClick={handleCancel}
              className="flex items-center space-x-1 px-3 py-1 bg-gray-600 text-white rounded-md hover:bg-gray-700"
            >
              <X className="h-4 w-4" />
              <span>Cancel</span>
            </button>
          </div>
        ) : (
          <button
            onClick={() => setIsEditing(true)}
            className="flex items-center space-x-1 px-3 py-1 bg-primary-600 text-white rounded-md hover:bg-primary-700"
          >
            <Edit2 className="h-4 w-4" />
            <span>Edit</span>
          </button>
        )}
      </div>

      {isEditing ? (
        <div className="space-y-4">
          <div>
            <label htmlFor={`slide-${slideNumber}-title`} className="block text-sm font-medium text-gray-700 mb-1">
              Slide Title
            </label>
            <input
              id={`slide-${slideNumber}-title`}
              type="text"
              value={editedTitle}
              onChange={(e) => {
                setEditedTitle(e.target.value);
                onUnsavedChange?.(true);
              }}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
              placeholder="Enter slide title"
            />
          </div>
          {slideNumber === 2 ? (
            <>
              <div>
                <label htmlFor={`slide-${slideNumber}-part1`} className="block text-sm font-medium text-gray-700 mb-1">
                  Part 1
                </label>
                <textarea
                  id={`slide-${slideNumber}-part1`}
                  value={editedPart1}
                  onChange={(e) => {
                    const text = e.target.value.slice(0, 720);
                    setEditedPart1(text);
                    onUnsavedChange?.(true);
                  }}
                  className="w-full h-48 p-4 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 resize-none"
                  placeholder="Enter part 1 content"
                  maxLength={720}
                />
                <div className="text-right text-sm text-gray-500">
                  {editedPart1.length}/720 characters
                </div>
                {renderRegenerateSection('part1')}
              </div>
              <div>
                <label htmlFor={`slide-${slideNumber}-part2`} className="block text-sm font-medium text-gray-700 mb-1">
                  Part 2
                </label>
                <textarea
                  id={`slide-${slideNumber}-part2`}
                  value={editedPart2}
                  onChange={(e) => {
                    const text = e.target.value.slice(0, 720);
                    setEditedPart2(text);
                    onUnsavedChange?.(true);
                  }}
                  className="w-full h-48 p-4 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 resize-none"
                  placeholder="Enter part 2 content"
                  maxLength={720}
                />
                <div className="text-right text-sm text-gray-500">
                  {editedPart2.length}/720 characters
                </div>
                {renderRegenerateSection('part2')}
              </div>
            </>
          ) : (
            <div>
              <label htmlFor={`slide-${slideNumber}-content`} className="block text-sm font-medium text-gray-700 mb-1">
                Slide Content
              </label>
              <textarea
                id={`slide-${slideNumber}-content`}
                value={editedContent}
                onChange={(e) => {
                  const text = e.target.value.slice(0, 720);
                  setEditedContent(text);
                  onUnsavedChange?.(true);
                }}
                className="w-full h-48 p-4 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500 resize-none"
                placeholder="Enter slide content"
                maxLength={720}
              />
              <div className="text-right text-sm text-gray-500">
                {editedContent.length}/720 characters
              </div>
              {renderRegenerateSection()}
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-4">
          <h3 className="text-xl font-bold text-gray-900">{editedTitle}</h3>
          {slideNumber === 2 ? (
            <div className="space-y-6">
              <div className="prose max-w-none">
                <h4 className="text-lg font-semibold text-gray-800">Part 1</h4>
                {renderContent(editedPart1)}
              </div>
              <div className="prose max-w-none">
                <h4 className="text-lg font-semibold text-gray-800">Part 2</h4>
                {renderContent(editedPart2)}
              </div>
            </div>
          ) : (
            <div className="prose max-w-none">
              {renderContent(editedContent)}
            </div>
          )}
        </div>
      )}
    </div>
  );
}