// src/utils/subscription.ts
import { PLAN_TOKENS } from '../config/plans';
import { sendUserWebhook } from './webhook';

export type PlanType = 'free' | 'basic' | 'pro';

export async function handleSubscription(stripeCustomerId: string | null, plan: PlanType): Promise<string> {
  if (!stripeCustomerId) {
    throw new Error('No Stripe customer ID provided');
  }

  try {
    const response = await fetch('https://hook.eu2.make.com/aeo5t4dwur4p6rx9krp1xa68ionksjhf', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        stripeCustomerId,
        plan
      })
    });

    if (!response.ok) {
      throw new Error('Failed to create checkout session');
    }

    const paymentLink = await response.text();
    return paymentLink;
  } catch (error) {
    console.error('Subscription error:', error);
    throw error;
  }
}

export async function getManageSubscriptionLink(stripeCustomerId: string | null): Promise<string> {
  if (!stripeCustomerId) {
    throw new Error('No Stripe customer ID provided');
  }

  try {
    const response = await fetch('https://hook.eu2.make.com/vcdf27iesljoflhr7zeff1aas2a7otyk', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        stripeCustomerId
      })
    });

    if (!response.ok) {
      throw new Error('Failed to get subscription management link');
    }

    const managementLink = await response.text();
    return managementLink;
  } catch (error) {
    console.error('Error getting subscription management link:', error);
    throw error;
  }
}

export async function checkAndUpdateSubscription(userId: string, userProfile: any) {
  try {
    await sendUserWebhook(
      userProfile.fullName,
      userProfile.email,
      userProfile.stripeCustomerId
    );
  } catch (error) {
    console.error('Error checking subscription:', error);
    throw error;
  }
}
