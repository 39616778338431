import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export async function downloadImage(url: string, filename: string) {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    saveAs(blob, filename);
  } catch (error) {
    console.error('Error downloading image:', error);
    throw new Error('Failed to download image');
  }
}

export async function downloadAllImages(images: { url: string; filename: string }[]) {
  try {
    const zip = new JSZip();
    
    const imgFolder = zip.folder('treatment-images');
    if (!imgFolder) throw new Error('Failed to create zip folder');

    const downloads = images.map(async ({ url, filename }) => {
      const response = await fetch(url);
      const blob = await response.blob();
      imgFolder.file(filename, blob);
    });

    await Promise.all(downloads);
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'treatment-images.zip');
  } catch (error) {
    console.error('Error creating zip archive:', error);
    throw new Error('Failed to download images');
  }
}

export async function downloadFile(url: string, filename: string) {
  try {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Download failed');
    const blob = await response.blob();
    saveAs(blob, filename);
  } catch (error) {
    console.error('Error downloading file:', error);
    throw new Error('Failed to download file');
  }
}

export function extractFileUrls(response: string): { pdfUrl: string; pptxUrl: string } {
  const pdfMatch = response.match(/\[PDF\]\s*(https?:\/\/[^\s]+)/);
  const pptxMatch = response.match(/\[PPTX\]\s*(https?:\/\/[^\s]+)/);

  return {
    pdfUrl: pdfMatch?.[1] || '',
    pptxUrl: pptxMatch?.[1] || ''
  };
}