import { useState } from 'react';
import { languages } from '../constants/languages';

export function useWizardState() {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0].name);
  const [webhookResponse, setWebhookResponse] = useState('');
  const [briefSummary, setBriefSummary] = useState('');
  const [commercialTitle, setCommercialTitle] = useState('');
  const [directorVision, setDirectorVision] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [slides, setSlides] = useState<{ content: string }[]>([]);
  const [styleDescriptors, setStyleDescriptors] = useState<string[]>([]);
  const [treatmentResponse, setTreatmentResponse] = useState('');
  const [visualsResponse, setVisualsResponse] = useState('');
  const [finalResponse, setFinalResponse] = useState('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [uploadedFileType, setUploadedFileType] = useState<1 | 2>(1);

  return {
    selectedLanguage,
    setSelectedLanguage,
    webhookResponse,
    setWebhookResponse,
    briefSummary,
    setBriefSummary,
    commercialTitle,
    setCommercialTitle,
    directorVision,
    setDirectorVision,
    isLoading,
    setIsLoading,
    slides,
    setSlides,
    styleDescriptors,
    setStyleDescriptors,
    treatmentResponse,
    setTreatmentResponse,
    visualsResponse,
    setVisualsResponse,
    finalResponse,
    setFinalResponse,
    uploadedFileUrl,
    setUploadedFileUrl,
    uploadedFileType,
    setUploadedFileType
  };
}