import React from 'react';
import { Download } from 'lucide-react';

interface DownloadButtonProps {
  onClick: () => void;
  isProcessing?: boolean;
  className?: string;
  label?: string;
}

export default function DownloadButton({ 
  onClick, 
  isProcessing = false,
  className = '',
  label = 'Download'
}: DownloadButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={isProcessing}
      className={`inline-flex items-center space-x-2 px-3 py-1 rounded-md text-sm 
        ${isProcessing 
          ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
          : 'bg-primary-100 text-primary-700 hover:bg-primary-200'
        } ${className}`}
    >
      <Download className="h-4 w-4" />
      <span>{isProcessing ? 'Processing...' : label}</span>
    </button>
  );
}