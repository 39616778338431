import React, { useState } from 'react';
import { X, Plus } from 'lucide-react';

interface StyleDescriptorsProps {
  descriptors: string[];
  onChange: (descriptors: string[]) => void;
}

export default function StyleDescriptors({ descriptors, onChange }: StyleDescriptorsProps) {
  const [newDescriptor, setNewDescriptor] = useState('');

  const handleAdd = () => {
    if (newDescriptor.trim()) {
      onChange([...descriptors, newDescriptor.trim()]);
      setNewDescriptor('');
    }
  };

  const handleDelete = (index: number) => {
    const newDescriptors = descriptors.filter((_, i) => i !== index);
    onChange(newDescriptors);
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleAdd();
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <h3 className="text-xl font-semibold text-gray-900 mb-4">Style Descriptors</h3>
      <div className="space-y-4">
        <div className="flex flex-wrap gap-2">
          {descriptors.map((descriptor, index) => (
            <div
              key={index}
              className="flex items-center bg-primary-100 text-primary-700 px-3 py-1 rounded-full"
            >
              <span className="mr-2">{descriptor}</span>
              <button
                onClick={() => handleDelete(index)}
                className="hover:text-primary-900"
              >
                <X className="h-4 w-4" />
              </button>
            </div>
          ))}
        </div>
        
        <div className="flex items-center space-x-2">
          <input
            type="text"
            value={newDescriptor}
            onChange={(e) => setNewDescriptor(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Add new style descriptor"
            className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
          />
          <button
            onClick={handleAdd}
            disabled={!newDescriptor.trim()}
            className="flex items-center space-x-1 px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Plus className="h-4 w-4" />
            <span>Add</span>
          </button>
        </div>
      </div>
    </div>
  );
}