import { useState, useCallback } from 'react';

export function useUnsavedChanges() {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [pendingAction, setPendingAction] = useState<(() => void) | null>(null);

  const handleUnsavedChanges = useCallback((action: () => void) => {
    if (hasUnsavedChanges) {
      setShowConfirmModal(true);
      setPendingAction(() => action);
    } else {
      action();
    }
  }, [hasUnsavedChanges]);

  const handleConfirm = useCallback(() => {
    if (pendingAction) {
      pendingAction();
      setHasUnsavedChanges(false);
      setShowConfirmModal(false);
      setPendingAction(null);
    }
  }, [pendingAction]);

  const handleCancel = useCallback(() => {
    setShowConfirmModal(false);
    setPendingAction(null);
  }, []);

  return {
    hasUnsavedChanges,
    setHasUnsavedChanges,
    showConfirmModal,
    handleUnsavedChanges,
    handleConfirm,
    handleCancel
  };
}