import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Video, 
  Palette, 
  Users, 
  Brain,
  PenTool,
  Target,
  Sparkles
} from 'lucide-react';

export default function LearnMore() {
  const navigate = useNavigate();

  const sections = [
    {
      title: 'What Exactly is RealTreat?',
      icon: <Brain className="h-8 w-8 text-primary-500" />,
      description: 'Imagine an AI that doesn\'t just generate content, but understands the soul of commercial storytelling. RealTreat is your digital creative partner, designed specifically for directors who want to work smarter, not harder.',
      features: [
        'Transforms complex briefs into compelling narratives',
        'AWork faster without losing your unique voice',
        'AI-powered, human-guided creative support',
        'Designed by filmmakers in mind'
      ]
    },
    {
      title: 'The Creative Workflow Revolution',
      icon: <PenTool className="h-8 w-8 text-primary-500" />,
      description: 'RealTreat isn\'t about replacing creativity – it\'s about removing the administrative barriers that keep you from your best work. We\'ve reimagined the treatment creation process to give you maximum creative freedom.',
      features: [
        'Intelligent brief parsing and analysis',
        'Concept generation that sparks, not dictates',
        'Fully customizable presentation templates',
        'Reduce treatment prep time by up to 90%'
      ]
    },
    {
      title: 'Visual Medium',
      icon: <Users className="h-8 w-8 text-primary-500" />,
      description: 'We know that commercials are a visual medium, which is why we generate over 30 images for each treatment to bring your idea to life.',
      features: [
        'High End image generation model',
        'Automatic prompts',
        'Allows regeneration of images',
        'Allows custom image uploads'
      ]
    },
    {
      title: 'Who Thrives with RealTreat?',
      icon: <Sparkles className="h-8 w-8 text-primary-500" />,
      description: 'Whether you\'re dreaming of your first commercial or you\'re a seasoned director with a wall full of awards, RealTreat levels up your creative game. From fresh talent to industry veterans, we\'ve got your back.',
      features: [
        'Make Your Treatment Pop with Pro-Level Design',
        'Always Ahead of Deadlines, Never Stressed',
        'Pitch Ideas That Clients Can\'t Say No To',
        'Turn Complex Briefs into Storytelling Magic'
      
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      {/* Navigation */}
      <nav className="bg-white/80 backdrop-blur-md fixed w-full z-10 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex items-center">
              <div 
                className="flex items-center space-x-2 cursor-pointer" 
                onClick={() => navigate('/')}
              >
                <Video className="h-8 w-8 text-primary-500" />
                <span className="text-2xl font-bold text-gray-900">RealTreat</span>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                onClick={() => navigate('/login')}
                className="px-4 py-2 text-gray-700 hover:text-gray-900"
              >
                Log in
              </button>
              <button
                onClick={() => navigate('/pricing')}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="pt-24 pb-16 text-center px-4 sm:px-6 lg:px-8">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl mb-6">
          How RealTreat Works
        </h1>
        <p className="max-w-2xl mx-auto text-xl text-gray-600">
  Transform your creative process with our AI-powered platform, specifically designed for commercial directors collaborating with agencies and clients.
        </p>
      </div>

      {/* Detailed Sections */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-16">
        <div className="space-y-16">
          {sections.map((section, index) => (
            <div key={index} className="bg-white/90 backdrop-blur-sm rounded-2xl p-8 shadow-lg">
              <div className="flex flex-col md:flex-row md:items-start md:space-x-8">
                <div className="flex-shrink-0 mb-6 md:mb-0">
                  <div className="bg-primary-100 rounded-full p-4">
                    {section.icon}
                  </div>
                </div>
                <div className="flex-1">
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    {section.title}
                  </h2>
                  <p className="text-lg text-gray-600 mb-6">
                    {section.description}
                  </p>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {section.features.map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-center space-x-2">
                        <Target className="h-5 w-5 text-primary-500" />
                        <span className="text-gray-700">{feature}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-white/40 backdrop-blur-sm py-16">
        <div className="max-w-4xl mx-auto text-center px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-8">
            Ready to Transform Your Creative Process?
          </h2>
          <div className="flex flex-col sm:flex-row justify-center space-y-4 sm:space-y-0 sm:space-x-4">
            <button
              onClick={() => navigate('/pricing')}
              className="px-8 py-3 bg-primary-600 text-white rounded-md hover:bg-primary-700 transform hover:scale-105 transition"
            >
              View Pricing Plans
            </button>
            <button
              onClick={() => navigate('/login')}
              className="px-8 py-3 bg-white text-primary-600 rounded-md hover:bg-gray-50 transform hover:scale-105 transition"
            >
              Sign Up Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}