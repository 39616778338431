// src/components/UserDashboardModal.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, User, Mail, CreditCard, AlertCircle, CheckCircle, Loader2 } from 'lucide-react';
import { updateEmail, updateProfile } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { useAuth } from '../contexts/AuthContext';
import { getManageSubscriptionLink, checkAndUpdateSubscription } from '../utils/subscription';

interface UserDashboardModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function UserDashboardModal({
  isOpen,
  onClose
}: UserDashboardModalProps) {
  const navigate = useNavigate();
  const { user, userProfile } = useAuth();
  const [fullName, setFullName] = useState(userProfile?.fullName || '');
  const [email, setEmail] = useState(user?.email || '');
  const [status, setStatus] = useState<{
    type: 'idle' | 'loading' | 'success' | 'error';
    message: string;
  }>({ type: 'idle', message: '' });

  if (!isOpen || !user) return null;

  const handleUpdateProfile = async () => {
    if (!fullName.trim()) return;

    setStatus({ type: 'loading', message: 'Updating profile...' });
    try {
      await updateProfile(user, { displayName: fullName });
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        fullName: fullName.trim(),
        updatedAt: new Date()
      });

      setStatus({ type: 'success', message: 'Profile updated successfully!' });
    } catch (error) {
      console.error('Error updating profile:', error);
      setStatus({ type: 'error', message: 'Failed to update profile. Please try again.' });
    }
  };

  const handleUpdateEmail = async () => {
    if (!email.trim() || email === user.email) return;

    setStatus({ type: 'loading', message: 'Sending verification email...' });
    try {
      await updateEmail(user, email);
      setStatus({ type: 'success', message: 'Verification email sent! Please check your inbox.' });
    } catch (error: any) {
      console.error('Error updating email:', error);
      setStatus({
        type: 'error',
        message: error.code === 'auth/requires-recent-login'
          ? 'Please log out and log in again to change your email'
          : 'Failed to update email. Please try again.'
      });
    }
  };

  const handleManageSubscription = async () => {
    try {
      setStatus({ type: 'loading', message: 'Getting subscription details...' });
      const managementLink = await getManageSubscriptionLink(userProfile?.stripeCustomerId || null);
      
      // Store current URL in sessionStorage
      sessionStorage.setItem('returnFromSubscription', 'true');
      
      // Navigate to management link in same window
      window.location.href = managementLink;
      
      onClose(); // Close the modal before navigation
    } catch (error) {
      console.error('Error managing subscription:', error);
      setStatus({ type: 'error', message: 'Failed to access subscription management. Please try again.' });
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-start mb-6">
          <h2 className="text-2xl font-semibold text-gray-900">Account Settings</h2>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {status.type !== 'idle' && (
          <div className={`mb-4 p-3 rounded-md flex items-center ${
            status.type === 'error' ? 'bg-red-50 text-red-700' :
            status.type === 'success' ? 'bg-green-50 text-green-700' :
            'bg-blue-50 text-blue-700'
          }`}>
            {status.type === 'error' && <AlertCircle className="h-5 w-5 mr-2" />}
            {status.type === 'success' && <CheckCircle className="h-5 w-5 mr-2" />}
            {status.type === 'loading' && <Loader2 className="h-5 w-5 mr-2 animate-spin" />}
            <span className="text-sm">{status.message}</span>
          </div>
        )}

        <div className="space-y-6">
          {/* Full Name Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              <User className="h-4 w-4 inline mr-2" />
              Full Name
            </label>
            <div className="flex space-x-2">
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                placeholder="Enter your full name"
              />
              <button
                onClick={handleUpdateProfile}
                disabled={status.type === 'loading' || !fullName.trim() || fullName === userProfile?.fullName}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
              >
                Update
              </button>
            </div>
          </div>

          {/* Email Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              <Mail className="h-4 w-4 inline mr-2" />
              Email Address
            </label>
            <div className="flex space-x-2">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                placeholder="Enter your email"
              />
              <button
                onClick={handleUpdateEmail}
                disabled={status.type === 'loading' || !email.trim() || email === user.email}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
              >
                Update
              </button>
            </div>
          </div>

          {/* Subscription Section */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              <CreditCard className="h-4 w-4 inline mr-2" />
              Subscription
            </label>
            <div className="flex justify-between items-center bg-gray-50 p-4 rounded-md">
              <div>
                <p className="font-medium text-gray-900">
                  Current Plan: {userProfile?.currentPlan?.charAt(0).toUpperCase() + userProfile?.currentPlan?.slice(1) || 'Free'}
                </p>
                <p className="text-sm text-gray-500">Manage your subscription and billing</p>
              </div>
              <button
                onClick={handleManageSubscription}
                disabled={status.type === 'loading'}
                className="px-4 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 disabled:opacity-50"
              >
                Manage Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
