import { useState, useEffect } from 'react';

export function useWizardNavigation() {
  const [currentStep, setCurrentStep] = useState(1);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToStep = (step: number) => {
    if (step >= 1 && step <= 5) {
      setCurrentStep(step);
    }
  };

  return {
    currentStep,
    setCurrentStep,
    handlePreviousStep,
    goToStep
  };
}