import React, { createContext, useContext, useState } from 'react';
import type { Template } from '../data/templates';

interface TemplateContextType {
  selectedTemplate: Template | null;
  setSelectedTemplate: (template: Template | null) => void;
}

const TemplateContext = createContext<TemplateContextType | null>(null);

export const useTemplate = () => {
  const context = useContext(TemplateContext);
  if (!context) {
    throw new Error('useTemplate must be used within a TemplateProvider');
  }
  return context;
};

export const TemplateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  return (
    <TemplateContext.Provider value={{ selectedTemplate, setSelectedTemplate }}>
      {children}
    </TemplateContext.Provider>
  );
};