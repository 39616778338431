import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTokens } from '../contexts/TokenContext';
import { CheckCircle, Loader2, AlertCircle } from 'lucide-react';
import { updateSubscriptionStatus } from '../utils/stripe';
import { PLAN_TOKENS } from '../config/plans';

export default function PaymentSuccess() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { updatePlan } = useTokens();
  const [isProcessing, setIsProcessing] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const processPayment = async () => {
      if (!user) {
        navigate('/login');
        return;
      }

      const params = new URLSearchParams(location.search);
      const sessionId = params.get('session_id');

      if (!sessionId) {
        setError('Invalid payment parameters');
        setIsProcessing(false);
        return;
      }

      try {
        // Get session details from Stripe
        const response = await fetch(`/.netlify/functions/get-session?sessionId=${sessionId}`);
        const session = await response.json();

        if (!session || !session.subscription) {
          throw new Error('Invalid session data');
        }

        const plan = session.plan as keyof typeof PLAN_TOKENS;
        const periodEnd = new Date(session.current_period_end * 1000);

        // Update subscription status in Firestore
        await updateSubscriptionStatus(user.uid, 'active', plan, periodEnd);

        // Update user's plan in tokens context
        await updatePlan(plan);
        
        // Redirect to dashboard after successful processing
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000);
      } catch (error) {
        console.error('Error processing payment:', error);
        setError('Failed to process payment. Please contact support if the issue persists.');
      } finally {
        setIsProcessing(false);
      }
    };

    processPayment();
  }, [user, location.search, navigate, updatePlan]);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full mx-4">
        {isProcessing ? (
          <div className="text-center">
            <Loader2 className="h-12 w-12 text-primary-600 animate-spin mx-auto mb-4" />
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Processing Payment
            </h2>
            <p className="text-gray-600">
              Please wait while we verify your payment...
            </p>
          </div>
        ) : error ? (
          <div className="text-center">
            <AlertCircle className="h-12 w-12 text-red-500 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Payment Error
            </h2>
            <p className="text-red-600 mb-6">{error}</p>
            <button
              onClick={() => navigate('/dashboard')}
              className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
            >
              Return to Dashboard
            </button>
          </div>
        ) : (
          <div className="text-center">
            <CheckCircle className="h-12 w-12 text-green-500 mx-auto mb-4" />
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">
              Payment Successful!
            </h2>
            <p className="text-gray-600 mb-6">
              Your subscription has been activated. You will be redirected to your dashboard shortly.
            </p>
            <button
              onClick={() => navigate('/dashboard')}
              className="px-6 py-2 bg-primary-600 text-white rounded-md hover:bg-primary-700"
            >
              Go to Dashboard
            </button>
          </div>
        )}
      </div>
    </div>
  );
}