import React, { createContext, useContext, useState, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { sendUserWebhook } from '../utils/webhook';

interface WebhookContextType {
  sendWebhookIfNeeded: () => Promise<void>;
}

const WebhookContext = createContext<WebhookContextType | null>(null);

export const useWebhook = () => {
  const context = useContext(WebhookContext);
  if (!context) {
    throw new Error('useWebhook must be used within WebhookProvider');
  }
  return context;
};

export const WebhookProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [hasWebhookBeenSent, setHasWebhookBeenSent] = useState(false);
  const { user, userProfile } = useAuth();

  const sendWebhookIfNeeded = useCallback(async () => {
    if (!user || !userProfile || hasWebhookBeenSent) {
      return;
    }

    try {
      await sendUserWebhook(
        userProfile.fullName,
        user.email || '',
        userProfile.stripeCustomerId || null
      );
      setHasWebhookBeenSent(true);
    } catch (error) {
      console.error('Failed to send webhook:', error);
    }
  }, [user, userProfile, hasWebhookBeenSent]);

  return (
    <WebhookContext.Provider value={{ sendWebhookIfNeeded }}>
      {children}
    </WebhookContext.Provider>
  );
};