import React, { useState, useEffect } from 'react';
import { Edit2, Save, X } from 'lucide-react';

interface CommercialTitleProps {
  initialTitle: string;
  onSave: (title: string) => void;
}

export default function CommercialTitle({ initialTitle, onSave }: CommercialTitleProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState(initialTitle);
  const [editedTitle, setEditedTitle] = useState(initialTitle);

  useEffect(() => {
    setTitle(initialTitle);
    setEditedTitle(initialTitle);
  }, [initialTitle]);

  const handleSave = () => {
    setTitle(editedTitle);
    onSave(editedTitle);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setEditedTitle(title);
    setIsEditing(false);
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="mb-2">
        <span className="text-sm font-medium text-gray-500">Presentation Title</span>
      </div>
      <div className="flex items-center justify-between">
        {isEditing ? (
          <div className="flex-1 flex items-center space-x-4">
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
              placeholder="Enter commercial title"
            />
            <div className="flex items-center space-x-2">
              <button
                onClick={handleSave}
                className="p-2 text-green-600 hover:text-green-700 transition-colors"
                title="Save changes"
              >
                <Save className="h-5 w-5" />
              </button>
              <button
                onClick={handleCancel}
                className="p-2 text-gray-600 hover:text-gray-700 transition-colors"
                title="Cancel editing"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        ) : (
          <>
            <h2 className="text-xl font-semibold text-gray-900">{title}</h2>
            <button
              onClick={() => setIsEditing(true)}
              className="p-2 text-gray-600 hover:text-gray-700 transition-colors group relative"
            >
              <Edit2 className="h-5 w-5" />
              <span className="absolute right-0 top-full mt-1 w-max bg-gray-800 text-white text-xs py-1 px-2 rounded opacity-0 group-hover:opacity-100 transition-opacity">
                Edit presentation title
              </span>
            </button>
          </>
        )}
      </div>
    </div>
  );
}