import React, { useState } from 'react';
import { Hash, X, Upload, Wand2, Loader2, Download } from 'lucide-react';
import LoadingImage from './LoadingImage';
import { useTokens } from '../contexts/TokenContext';
import { TOKEN_COSTS } from '../config/tokenCosts';
import { downloadImage } from '../utils/downloadUtils';
import type { ImageDimensions, ImageResolution } from '../types/visuals';

interface ImageCardProps {
  slideNumber: number;
  imageIndex: number;
  image: {
    prompt: string;
    link: string;
    isDeleted?: boolean;
    isGenerating?: boolean;
    dimensions?: ImageDimensions;
    resolution?: ImageResolution;
  };
  editablePrompt: string;
  onDelete: (dimensions?: ImageDimensions) => void;
  onPromptChange: (newPrompt: string) => void;
  onFileUpload: (file: File) => Promise<void>;
  onGenerateNew: (dimensions?: ImageDimensions) => Promise<void>;
}

export default function ImageCard({
  slideNumber,
  imageIndex,
  image,
  editablePrompt,
  onDelete,
  onPromptChange,
  onFileUpload,
  onGenerateNew
}: ImageCardProps) {
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const { deductTokens } = useTokens();

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;
    
    setIsUploading(true);
    try {
      await onFileUpload(file);
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Failed to upload image');
    } finally {
      setIsUploading(false);
    }
  };

  const handleGenerateNew = async () => {
    try {
      await onGenerateNew(image.dimensions);
    } catch (error) {
      alert(error instanceof Error ? error.message : 'Failed to generate new image');
    }
  };

  const handleDownload = async () => {
    if (!image.link || image.isDeleted || image.isGenerating) return;
    
    setIsDownloading(true);
    try {
      await downloadImage(image.link, `slide-${slideNumber}-image-${imageIndex + 1}.jpg`);
    } catch (error) {
      alert('Failed to download image. Please try again.');
    } finally {
      setIsDownloading(false);
    }
  };

  const handleImageDelete = () => {
    onDelete(image.dimensions);
  };

  const renderPromptTooltip = () => {
    let tooltipContent = image.prompt;
    if (image.resolution?.suggestedResolution) {
      tooltipContent += `\n\nSuggested resolution: ${image.resolution.suggestedResolution}`;
    }
    return tooltipContent;
  };

  if (image.isDeleted) {
    return (
      <div className="aspect-video bg-gray-100 rounded-lg p-4 space-y-4">
        <textarea
          value={editablePrompt}
          onChange={(e) => onPromptChange(e.target.value)}
          className="w-full h-24 p-2 border border-gray-300 rounded-md resize-none"
          placeholder="Enter image prompt..."
        />
        <div className="flex space-x-2">
          <label className={`flex-1 cursor-pointer ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleFileUpload}
              disabled={isUploading}
            />
            <div className="flex items-center justify-center space-x-2 p-2 bg-gray-200 rounded-md hover:bg-gray-300">
              {isUploading ? (
                <Loader2 className="h-4 w-4 animate-spin" />
              ) : (
                <Upload className="h-4 w-4" />
              )}
              <span>{isUploading ? 'Uploading...' : 'Upload Image'}</span>
            </div>
          </label>
          <button
            onClick={handleGenerateNew}
            disabled={image.isGenerating || isUploading}
            className={`flex-1 flex items-center justify-center space-x-2 p-2 bg-primary-600 text-white rounded-md hover:bg-primary-700 
              ${(image.isGenerating || isUploading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {image.isGenerating ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Wand2 className="h-4 w-4" />
            )}
            <span>{image.isGenerating ? 'Generating...' : 'Generate New'}</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="h-[400px] rounded-lg bg-gray-100 overflow-hidden relative">
      <LoadingImage
        src={image.link}
        alt={`Visual for slide`}
        className="transition-opacity duration-200"
        isGenerating={image.isGenerating}
        onImageLoaded={async (dimensions) => {
          if (image.isGenerating) {
            await deductTokens(TOKEN_COSTS.IMAGE_GENERATION);
            image.dimensions = dimensions;
          }
        }}
      />
      <div className="absolute inset-0 flex flex-col justify-between pointer-events-none">
        <div className="p-2 flex justify-between">
          <button
            onClick={handleImageDelete}
            disabled={image.isGenerating || isUploading}
            className={`p-1 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors pointer-events-auto
              ${(image.isGenerating || isUploading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <X className="h-4 w-4" />
          </button>
          <div className="relative group pointer-events-auto">
            <button className="p-1 bg-black/50 text-white rounded-md hover:bg-black/60 transition-colors">
              <Hash className="h-4 w-4" />
            </button>
            <div className="absolute right-0 top-full mt-2 w-64 p-3 bg-black/90 text-white text-sm rounded-md invisible group-hover:visible transform translate-y-1 opacity-0 group-hover:translate-y-0 group-hover:opacity-100 transition-all duration-200 z-[3] whitespace-pre-line">
              {renderPromptTooltip()}
            </div>
          </div>
        </div>
        <div className="p-2 flex justify-end">
          <button
            onClick={handleDownload}
            disabled={isDownloading || image.isGenerating}
            className={`p-1 bg-primary-600 text-white rounded-md hover:bg-primary-700 transition-colors pointer-events-auto
              ${(isDownloading || image.isGenerating) ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            {isDownloading ? (
              <Loader2 className="h-4 w-4 animate-spin" />
            ) : (
              <Download className="h-4 w-4" />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}