import React from 'react';

export default function LoadingMessage({ isLoading }: { isLoading: boolean }) {
  if (!isLoading) return null;

  return (
    <div className="flex-1 text-center">
      <span className="text-primary-600 bg-primary-50 px-4 py-2 rounded-full italic">
        Please wait...
      </span>
    </div>
  );
}