import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react';
import type { ImageDimensions } from '../types/visuals';

interface LoadingImageProps {
  src: string;
  alt: string;
  className?: string;
  isGenerating?: boolean;
  onImageLoaded?: (dimensions: ImageDimensions) => void;
}

export default function LoadingImage({ 
  src, 
  alt, 
  className = '', 
  isGenerating = false,
  onImageLoaded 
}: LoadingImageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [naturalDimensions, setNaturalDimensions] = useState<ImageDimensions | null>(null);

  useEffect(() => {
    if (!src) return;
    
    setIsLoading(true);
    setError(false);
    setNaturalDimensions(null);

    const img = new Image();
    img.src = src;

    const handleLoad = () => {
      const dimensions: ImageDimensions = {
        width: img.naturalWidth,
        height: img.naturalHeight
      };
      setNaturalDimensions(dimensions);
      setIsLoading(false);
      setError(false);
      
      if (onImageLoaded) {
        onImageLoaded(dimensions);
      }
    };

    const handleError = () => {
      setIsLoading(false);
      setError(true);
    };

    img.addEventListener('load', handleLoad);
    img.addEventListener('error', handleError);

    return () => {
      img.removeEventListener('load', handleLoad);
      img.removeEventListener('error', handleError);
    };
  }, [src, onImageLoaded]);

  const getImageStyle = () => {
    if (!naturalDimensions) return {};

    const aspectRatio = naturalDimensions.width / naturalDimensions.height;
    
    if (aspectRatio < 1) {
      return {
        height: '100%',
        width: 'auto',
        objectFit: 'contain' as const
      };
    }
    
    return {
      width: '100%',
      height: 'auto',
      objectFit: 'contain' as const
    };
  };

  return (
    <div className="relative w-full h-full bg-gray-100 overflow-hidden flex items-center justify-center">
      {(isLoading || isGenerating) && (
        <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
          <Loader2 className="h-8 w-8 animate-spin text-primary-600 mb-2" />
          <span className="text-sm text-primary-600 font-medium">
            {isGenerating ? 'Generating image...' : 'Loading image...'}
          </span>
        </div>
      )}
      {src && (
        <img
          src={error ? 'https://placehold.co/600x400?text=Image+Not+Available' : src}
          alt={alt}
          className={`max-w-full max-h-full transition-opacity duration-200 ${className} ${
            (isLoading || isGenerating) ? 'opacity-0' : 'opacity-100'
          }`}
          style={getImageStyle()}
        />
      )}
    </div>
  );
}