import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { sendUserWebhook } from '../webhook';
import { shouldRefreshTokens, refreshUserTokens } from './tokenRefresh';
import { PlanType } from '../../types/subscription';

export class SubscriptionError extends Error {
  constructor(message: string, public readonly code: string) {
    super(message);
    this.name = 'SubscriptionError';
  }
}

export async function syncSubscriptionStatus(userId: string): Promise<void> {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new SubscriptionError('User not found', 'user_not_found');
    }

    const userData = userDoc.data();
    const lastTokenReset = userData.lastTokenReset?.toDate();
    const createdAt = userData.createdAt?.toDate();
    const currentPlan = userData.currentPlan || 'free';
    const subscriptionPeriodEnd = userData.subscriptionPeriodEnd?.toDate();

    if (!lastTokenReset || !createdAt) {
      throw new SubscriptionError('Invalid user data', 'invalid_user_data');
    }

    const shouldRefresh = shouldRefreshTokens({
      lastTokenReset,
      currentPlan: currentPlan as PlanType,
      subscriptionPeriodEnd,
      createdAt
    });

    if (!shouldRefresh) {
      return;
    }

    // Verify subscription status with webhook
    await sendUserWebhook(
      userData.fullName,
      userData.email,
      userData.stripeCustomerId
    );

    // Refresh tokens based on current plan
    await refreshUserTokens(userId, currentPlan as PlanType);
  } catch (error) {
    if (error instanceof SubscriptionError) {
      throw error;
    }
    throw new SubscriptionError(
      'Failed to sync subscription',
      'sync_failed'
    );
  }
}