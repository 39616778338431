import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../config/firebase';
import { syncSubscriptionStatus, SubscriptionError } from '../utils/subscription/subscriptionSync';
import { deductTokensWithRetry, TokenDeductionError } from '../utils/tokens/deduction';
import { PLAN_TOKENS } from '../config/plans';
import type { PlanType } from '../types/subscription';

interface TokenContextType {
  tokens: number;
  currentPlan: PlanType;
  loading: boolean;
  error: string | null;
  updateTokens: (newAmount: number) => Promise<void>;
  updatePlan: (newPlan: PlanType) => Promise<void>;
  deductTokens: (amount: number) => Promise<boolean>;
  checkTokens: (amount: number) => boolean;
  refreshTokens: () => Promise<void>;
}

const TokenContext = createContext<TokenContextType | null>(null);

export const useTokens = () => {
  const context = useContext(TokenContext);
  if (!context) {
    throw new Error('useTokens must be used within a TokenProvider');
  }
  return context;
};

export const TokenProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [tokens, setTokens] = useState<number>(0);
  const [currentPlan, setCurrentPlan] = useState<PlanType>('free');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth();

  const refreshTokens = useCallback(async () => {
    if (!user) return;

    try {
      await syncSubscriptionStatus(user.uid);
      setError(null);
    } catch (error) {
      if (error instanceof SubscriptionError) {
        setError(`Failed to refresh tokens: ${error.message}`);
      } else {
        setError('Failed to refresh tokens. Please try again.');
      }
      console.error('Error refreshing tokens:', error);
    }
  }, [user]);

  useEffect(() => {
    if (!user) {
      setTokens(0);
      setCurrentPlan('free');
      setLoading(false);
      return undefined;
    }

    const userRef = doc(db, 'users', user.uid);
    
    // Initial token check
    refreshTokens();

    // Set up real-time listener with error handling and retry logic
    const unsubscribe = onSnapshot(
      userRef,
      {
        next: (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            setTokens(data.tokens ?? PLAN_TOKENS.free);
            setCurrentPlan((data.currentPlan ?? 'free') as PlanType);
            setError(null);
          } else {
            setTokens(PLAN_TOKENS.free);
            setCurrentPlan('free');
          }
          setLoading(false);
        },
        error: (err) => {
          console.error('Error in token snapshot listener:', err);
          setError('Failed to sync token updates. Please refresh the page.');
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [user, refreshTokens]);

  const deductTokens = useCallback(async (amount: number): Promise<boolean> => {
    if (!user || tokens < amount) return false;

    try {
      const success = await deductTokensWithRetry(user.uid, amount);
      if (!success) {
        setError('Insufficient tokens');
      }
      return success;
    } catch (error) {
      if (error instanceof TokenDeductionError) {
        setError(`Failed to deduct tokens: ${error.message}`);
      } else {
        setError('Failed to deduct tokens. Please try again.');
      }
      console.error('Error deducting tokens:', error);
      return false;
    }
  }, [user, tokens]);

  const value = {
    tokens,
    currentPlan,
    loading,
    error,
    updateTokens: useCallback(async (newAmount: number) => {
      if (!user) return;
      const userRef = doc(db, 'users', user.uid);
      await userRef.update({
        tokens: newAmount,
        updatedAt: new Date()
      });
    }, [user]),
    updatePlan: useCallback(async (newPlan: PlanType) => {
      if (!user) return;
      const userRef = doc(db, 'users', user.uid);
      await userRef.update({
        currentPlan: newPlan,
        tokens: PLAN_TOKENS[newPlan],
        updatedAt: new Date(),
        lastTokenReset: new Date()
      });
    }, [user]),
    deductTokens,
    checkTokens: useCallback((amount: number): boolean => tokens >= amount, [tokens]),
    refreshTokens
  };

  return (
    <TokenContext.Provider value={value}>
      {children}
    </TokenContext.Provider>
  );
};