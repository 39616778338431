import React from 'react';
import TopBar from '../components/TopBar';
import ManageSubscription from '../components/ManageSubscription';

export default function ManageSubscriptionPage() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-primary-50 via-secondary-50 to-accent-50">
      <TopBar />
      <div className="pt-16">
        <ManageSubscription />
      </div>
    </div>
  );
}